export const SignupTranslations = {
  SIGNUP_COMPANY_NAME_INPUT: 'Bedrijfsnaam',
  SIGNUP_FIRST_NAME_INPUT: 'Voornaam',
  SIGNUP_LAST_NAME_INPUT: 'Achternaam',
  SIGNUP_EMAIL_INPUT: 'Email adres',
  SIGNUP_PASSWORD_INPUT: 'Wachtwoord',
  SIGNUP_BUTTON_TEXT: 'Aanmelden',

  SIGNUP_NO_SUGARDADDY_ERROR_TITLE: 'Foutje',
  SIGNUP_NO_SUGARDADDY_ERROR_MESSAGE: 'Het lijkt er op dat je geen sugardaddy bent :(',

  SIGNUP_SUCCESS_TITLE: 'Je aanmelding is ontvangen.',
  SIGNUP_SUCCESS_MESSAGE: 'We hebben een email gestuurd om je account te bevestigen. Klik "Bevestig account" om door te gaan met de aanmelding.',

  SIGNUP_EMAIL_ERROR_TITLE: 'Oops.',
  SIGNUP_EMAIL_ERROR_MESSAGE: 'Dit email adres is al in gebruik.'
};
