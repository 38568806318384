import { contactActions } from "./contact.action.types";
import { api } from "../../lib/api";

export const sendContactForm = (name, email, feedback, platform) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: contactActions.SEND_CONTACT_FORM_PENDING
      });

      console.log("SENDING FORM WITH DATA", name, email, feedback, platform);

      await api.post('/feedback/', {
        user_name: name,
        email: email,
        feedback: feedback,
        platform: platform
      });

      dispatch({
        type: contactActions.SEND_CONTACT_FORM_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: contactActions.SEND_CONTACT_FORM_ERROR,
        error: error,
      })
    }
  }
};
