import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { DownloadComponent } from "../../components/download/download.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Section, Title } from "../../components/section";

// Redux
import { getMembers } from "../../store/team/team.actions";
import { connect } from 'react-redux';

// Stylesheet
import colors from '../../colors.scss';
import './team.container.scss';


class TeamContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.getMembers();
  }

  render = ({history, team, client} = this.props) => (
    <div className="teamContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>

      <Section name="team">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('TEAM_TITLE')}
                     subTitle={i18n.translate('TEAM_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
          <div className="row">
            {team.members.map((member, index) => (
              <div key={index} className="member col-sm-12 col-md-6 col-lg-4">
                {member.image && (
                  <div className="imageContainer">
                    <div className="image" style={{backgroundImage: `url(${member.image.data.url})`}}/>
                  </div>
                )}
                <h1 className="name">{member.name}</h1>
                <h3 className="role">{member.role}</h3>
                <div className="story" dangerouslySetInnerHTML={{__html: member.translations.data.find((translation) => translation.language_code === client.info.location.countryCode).story}}/>
                <div className="linkedin">
                  <a className="link" href={member.linkedin} target="_blank">in</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    client: state.client,
    team: state.team
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMembers: () => dispatch(getMembers())
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(TeamContainer));

export { connectedContainer as TeamContainer };
