import PropTypes from 'prop-types';
import React from 'react';

import './switch.component.scss';

export class Switch extends React.PureComponent {
  static propTypes = {
    onToggle: PropTypes.func.isRequired,
    active: PropTypes.bool,

    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onToggle: (bool) => console.log("default Switch onToggle function", bool),
    active: false,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      active: props.active,
    }
  }

  componentDidMount() {
    if (this.state.active && !this.props.disabled) {
      this.switchContainer.classList.toggle('active');
      this.switchToggle.classList.toggle('active');
    }

    if (this.props.disabled) {
      this.switchContainer.classList.add('disabled');
      this.switchToggle.classList.add('disabled');
    }
  }

  render = () => (
    <div className="switchContainer" ref={(ref) => this.switchContainer = ref} onClick={() => this.toggle()}>
      <div className="thumb" ref={(ref) => this.switchToggle = ref}/>
    </div>
  );

  toggle = () => {
    if (!this.props.disabled) {
      this.setState({active: !this.state.active}, () => {
        this.switchContainer.classList.toggle('active');
        this.switchToggle.classList.toggle('active');
        this.props.onToggle(this.state.active);
      });
    }
  }
}
