import colors from '../../colors.scss';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Switch } from "../switch/switch.component";
import { Button } from "../button";

// Stylesheets
import './cookie.consent.component.scss';

export class CookieConsent extends React.PureComponent {
  static propTypes = {
    setGDPRConsent: PropTypes.func,
    onPressAllow: PropTypes.func,
  };

  static defaultProps = {
    setGDPRConsent: (cookieSettings) => console.log("default setGDPRConsent", cookieSettings),
    onPressAllow: () => console.log("default onPressAllow")
  };

  constructor(props) {
    super(props);

    this.state = {
      functional: true,
      statistics: true,
      performance: true,
      targeting: true,
    }
  }

  render = () => (
    <div className="cookieConsentContainer container">
      <div className="row">
        <div className="cookieHeader col-xs-12 col-md-12 col-lg-12 col-xl-12">
          <h1 className="cookieTitle">Tranzer maakt gebruik van cookies.</h1>
          <p className="cookieDescription">Wij plaatsen standaard Functionele cookies, om deze website naar behoren te laten functioneren en Analytische cookies waarmee wij het gebruik van de website kunnen meten. Deze cookies gebruiken geen persoonsgegevens.</p>
        </div>
      </div>

      <div className="row cookieConsentForm">
        <div className="cookieContainer col-xs-12 col-md-3 col-lg-3 col-xl-3">
          <div className="toggleContainer">
            <Switch
              disabled={true}
              active={this.state.functional}
              onToggle={() => console.log("Toggle")}/>
            <h3 className="cookieTitle">Functioneel</h3>
          </div>
          <div className="descriptionContainer">
            <p>Functionele cookies zijn <b>vereist</b> om de basisfunctionaliteit van de website te kunnen leveren en zijn daarom permanent ingeschakeld.</p>
          </div>
        </div>

        <div className="cookieContainer col-xs-12 col-md-3 col-lg-3 col-xl-3">
          <div className="toggleContainer">
            <Switch
              disabled={true}
              active={this.state.functional}
              onToggle={() => console.log("Toggle")}/>
            <h3 className="cookieTitle">Statistieken</h3>
          </div>
          <div className="descriptionContainer">
            <p>Cookies voor onze Analyse zijn <b>vereist</b> voor het verbeteren en aanpassen van de website en daarom permanent ingeschakeld.</p>
          </div>
        </div>

        <div className="cookieContainer col-xs-12 col-md-3 col-lg-3 col-xl-3">
          <div className="toggleContainer">
            <Switch
              active={this.state.performance}
              onToggle={(bool) => this.togglePerformance(bool)}/>
            <h3 className="cookieTitle">Performance</h3>
          </div>
          <div className="descriptionContainer">
            <p>Met behulp van performancecookies kunnen we de functionaliteit van de site verbeteren door jouw gebruik van de website bij te houden.</p>
          </div>
        </div>


        <div className="cookieContainer col-xs-12 col-md-3 col-lg-3 col-xl-3">
          <div className="toggleContainer">
            <Switch
              active={this.state.targeting}
              onToggle={(bool) => this.toggleTargeting(bool)}/>
            <h3 className="cookieTitle">Targeting</h3>
          </div>
          <div className="descriptionContainer">
            <p>Deze cookies verbinden met je sociale netwerken en stellen je in staat om content van onze website te delen via social media.</p>
          </div>
        </div>
      </div>

      <div className="cookieConsentButtons">
        <Button
          title="Meer informatie"
          backgroundColor={colors.c3}
          onClick={() => this.saveSettings()}/>

        <Button
          title="Instellingen opslaan"
          backgroundColor={colors.primary}
          onClick={() => this.saveSettings()}/>

      </div>
    </div>
  );

  togglePerformance = (bool) => {
    this.setState({performance: bool});
  };

  toggleTargeting = (bool) => {
    this.setState({targeting: bool});
  };

  saveSettings = () => {
    this.props.onPressAllow(this.state);
  }
}
