export const accountActionTypes = {
  GET_TRAVELER_GROUPS_PENDING: 'GET_TRAVELER_GROUPS_PENDING',
  GET_TRAVELER_GROUPS_SUCCESS: 'GET_TRAVELER_GROUPS_SUCCESS',
  GET_TRAVELER_GROUPS_ERROR: 'GET_TRAVELER_GROUPS_ERROR',

  GET_TRAVELER_GROUP_PENDING: 'GET_TRAVELER_GROUP_PENDING',
  GET_TRAVELER_GROUP_SUCCESS: 'GET_TRAVELER_GROUP_SUCCESS',
  GET_TRAVELER_GROUP_ERROR: 'GET_TRAVELER_GROUP_ERROR',

  SET_TRAVELER_GROUPS_PENDING: 'SET_TRAVELER_GROUPS_PENDING',
  SET_TRAVELER_GROUPS_SUCCESS: 'SET_TRAVELER_GROUPS_SUCCESS',
  SET_TRAVELER_GROUPS_ERROR: 'SET_TRAVELER_GROUPS_ERROR',

  UPDATE_TRAVELERS_PENDING: 'UPDATE_TRAVELERS_PENDING',
  UPDATE_TRAVELERS_SUCCESS: 'UPDATE_TRAVELERS_SUCCESS',
  UPDATE_TRAVELERS_ERROR: 'UPDATE_TRAVELERS_ERROR',

  LOGIN_PENDING: 'LOGIN_PENDING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  CHECK_EMAIL_PENDING: 'CHECK_EMAIL_PENDING',
  CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_ERROR: 'CHECK_EMAIL_ERROR',

  SIGNUP_PENDING: 'SIGNUP_PENDING',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',

  GET_PROFILE_PENDING: 'GET_PROFILE_PENDING',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',

  UPDATE_PROFILE_PENDING: 'UPDATE_PROFILE_PENDING',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',

  ADD_TRAVELER_GROUP_PENDING: 'ADD_TRAVELER_GROUP_PENDING',
  ADD_TRAVELER_GROUP_SUCCESS: 'ADD_TRAVELER_GROUP_SUCCESS',
  ADD_TRAVELER_GROUP_ERROR: 'ADD_TRAVELER_GROUP_ERROR',

  UPDATE_TRAVELER_GROUP_PENDING: 'UPDATE_TRAVELER_GROUP_PENDING',
  UPDATE_TRAVELER_GROUP_SUCCESS: 'UPDATE_TRAVELER_GROUP_SUCCESS',
  UPDATE_TRAVELER_GROUP_ERROR: 'UPDATE_TRAVELER_GROUP_ERROR',

  DELETE_TRAVELER_GROUP_PENDING: 'DELETE_TRAVELER_GROUP_PENDING',
  DELETE_TRAVELER_GROUP_SUCCESS: 'DELETE_TRAVELER_GROUP_SUCCESS',
  DELETE_TRAVELER_GROUP_ERROR: 'DELETE_TRAVELER_GROUP_ERROR',

  VERIFY_SEPA_PENDING: 'VERIFY_SEPA_PENDING',
  VERIFY_SEPA_SUCCESS: 'VERIFY_SEPA_SUCCESS',
  VERIFY_SEPA_ERROR: 'VERIFY_SEPA_ERROR',

  REMOVE_SEPA: 'REMOVE_SEPA',

  // NON A-SYNC ACTIONS

  LOGOUT_USER: 'LOGOUT_USER',
  UPDATE_SIGNUP_STEPS: 'UPDATE_SIGNUP_STEPS'
};
