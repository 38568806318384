import { platform, toJS } from "../../lib/helpers";
import { BeatLoader } from 'react-spinners';
import FireBase from 'firebase';
import React from 'react';

// Compontents
import { Navigation } from "../../components/navigation/navigation.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Section, Title } from "../../components/section";
import { Button } from "../../components/button";

// Redux
import { createDynamicLink } from '../../store/client/client.actions';

import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './magiclink.container.scss';


class MagicLinkContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      platform: platform(),
      showButtons: false,
      redirectTitle: 'Just a second',
      redirectSubtitle: 'We are now creating a magic link to our app'
    };

    let config = {
      apiKey: "AIzaSyAtjXPzA8adaejVohVj-bdReGVqN0idbh8",
      authDomain: "tranzer-7d344.firebaseapp.com",
      databaseURL: "https://tranzer-7d344.firebaseio.com",
      projectId: "tranzer-7d344",
      storageBucket: "tranzer-x.appspot.com",
      messagingSenderId: "298071319819"
    };

    if (FireBase.apps.length === 0) {
      FireBase.initializeApp(config);
    }
  }

  componentDidMount({match} = this.props) {
    // Generate pirateUUID and create dynamic link for iOS and Android only. Desktop show the buttons after timeout.
    let hash = match.params.magicLink;
    if (platform() !== 'desktop') {
      this.props.createDynamicLink(hash)
        .then((link) => {
          console.log('dymaicLink', link);
          // window.open(link.shortLink, '_self');
        });
    } else {
      setTimeout(() => {
        this.setState({
          showButtons: !this.state.showButtons,
          redirectTitle: 'Manual download',
          redirectSubtitle: 'We could not find the store matching your device, are you on desktop?'
        });
      }, 1000)
    }
  }

  render = ({history} = this.props) => (
    <div className="magicLinkContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>

      <Section name="faq">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title
                className="no-padding"
                title="Just a second"
                subTitle="We are now creating a magic link to our app"
                sectionColor={colors.c1}
                color={colors.primary}/>
            </div>
          </div>
        </div>
      </Section>

      {!this.state.showButtons && (
        <BeatLoader color={colors.primary} size={10}/>
      )}

      {this.state.showButtons && (
        <div className="manualDownload">
          <div className="storeButtons">
            <img className="storeButton" alt="AppStore" src={require('./res/app-store.png')} onClick={() => this.redirectStore('ios')}/>
            <img className="storeButton" alt="PlayStore" src={require('./res/google-play.png')} onClick={() => this.redirectStore('android')}/>
          </div>
          <div className="back">
            <h3 className="center">or</h3>
            <Button title="Back to website" backgroundColor={colors.primary} onClick={() => this.props.history.push('/')}/>
          </div>
        </div>
      )}

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    news: state.news,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDynamicLink: (hash) => dispatch(createDynamicLink(hash))
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(MagicLinkContainer));

export { connectedContainer as MagicLinkContainer };
