// Web routes
import {HomeContainer} from "./containers/home/home.container";
import {OperatorContainer} from "./containers/operator/operator.container";
import {BlogContainer} from "./containers/blog/blog.container";
import {PostContainer} from "./containers/blog/post.container";
import {NewsContainer} from "./containers/news/news.container";
import {TeamContainer} from "./containers/team/team.container";
import {JobsContainer} from "./containers/jobs/jobs.container";
import {JobContainer} from "./containers/jobs/job.container";
import {FAQContainer} from "./containers/faq/faq.container";
import {BusinessContainer} from "./containers/business/business.container";
import {ContactContainer} from "./containers/contact/contact.container";
import {TermsContainer} from "./containers/terms/terms.container";
import {PrivacyContainer} from "./containers/privacy/privacy.container";
import {VerificationContainer} from "./containers/verification/verification.container";
import {StoreContainer} from "./containers/store/store.container";
import {CxxJasContainer} from "./containers/cxx_jas/cxx_jas";
import {MagicLinkContainer} from "./containers/magiclink/magiclink.container";
import {ShareContainer} from "./containers/share/share.container";

export const routes = [
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Tranzer",
            EN: "Tranzer"
        },
        path: {
            NL: '/',
            EN: '/',
        },
        component: HomeContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Tranzer",
            EN: "Tranzer"
        },
        path: {
            NL: '/home/:pirate/',
            EN: '/home/:pirate/',
        },
        component: HomeContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Vervoerder",
            EN: "Operator"
        },
        path: {
            NL: '/vervoerder/',
            EN: '/operator/',
        },
        component: OperatorContainer
    },
    {
        visible: true,
        footerVisible: true,
        exact: true,
        title: {
            NL: "Nieuws",
            EN: "News"
        },
        path: {
            NL: '/nieuws/',
            EN: '/news/',
        },
        component: NewsContainer
    },
    {
        visible: false,
        footerVisible: true,
        exact: true,
        title: {
            NL: "Post",
            EN: "Post"
        },
        path: {
            NL: '/blog/:post',
            EN: '/blog/:post',
        },
        component: PostContainer
    },
    {
        visible: true,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Team",
            EN: "Team"
        },
        path: {
            NL: '/team/',
            EN: '/team/',
        },
        component: TeamContainer
    },
    {
        visible: true,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Vacatures",
            EN: "Job offers"
        },
        path: {
            NL: '/vacatures/',
            EN: '/job-offers/',
        },
        component: JobsContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Vacature",
            EN: "Job offer"
        },
        path: {
            NL: '/vacatures/:offer',
            EN: '/job-offers/:offer',
        },
        component: JobContainer
    },
    {
        visible: true,
        footerVisible: true,
        exact: true,
        title: {
            NL: "FAQ",
            EN: "FAQ"
        },
        path: {
            NL: '/faq/',
            EN: '/faq/',
        },
        component: FAQContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "FAQ",
            EN: "FAQ"
        },
        path: {
            NL: '/:countryCode/faq/',
            EN: '/:countryCode/faq/',
        },
        component: FAQContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Zakelijk",
            EN: "Business"
        },
        path: {
            NL: '/zakelijk/',
            EN: '/business/',
        },
        component: BusinessContainer
    },
    {
        visible: true,
        footerVisible: true,
        exact: true,
        title: {
            NL: "Contact",
            EN: "Contact"
        },
        path: {
            NL: '/contact/',
            EN: '/contact/',
        },
        component: ContactContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "JAS Abonnement",
            EN: "JAS Subscription"
        },
        path: {
            NL: '/JAS/',
            EN: '/JAS/',
        },
        component: CxxJasContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Store",
            EN: "Store"
        },
        path: {
            NL: '/store/',
            EN: '/store/',
        },
        component: StoreContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: true,
        title: {
            NL: "Store",
            EN: "Store"
        },
        path: {
            NL: '/store/:platform',
            EN: '/store/:platform',
        },
        component: StoreContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "Algemene Voorwaarden",
            EN: "Terms & Conditions"
        },
        path: {
            NL: '/algemene-voorwaarden/',
            EN: '/terms-and-conditions/',
        },
        component: TermsContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "Algemene Voorwaarden",
            EN: "Terms & Conditions"
        },
        path: {
            NL: '/:countryCode/terms-and-conditions/',
            EN: '/:countryCode/terms-and-conditions/',
        },
        component: TermsContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "Privacyverklaring",
            EN: "Privacy Statement"
        },
        path: {
            NL: '/:countryCode/privacy-policy/',
            EN: '/:countryCode/privacy-policy/',
        },
        component: PrivacyContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "Privacyverklaring",
            EN: "Privacy Statement"
        },
        path: {
            NL: '/privacyverklaring/',
            EN: '/privacy-statement/',
        },
        component: PrivacyContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "Verificatie",
            EN: "Verification"
        },
        path: {
            NL: '/verification/:countryCode/:success',
            EN: '/verification/:countryCode/:success',
        },
        component: VerificationContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "Magic Link",
            EN: "Magic Link"
        },
        path: {
            NL: '/magic/:magicLink/',
            EN: '/magic/:magicLink/',
        },
        component: MagicLinkContainer
    },
    {
        visible: false,
        footerVisible: false,
        exact: false,
        title: {
            NL: "Share Link",
            EN: "Share Link"
        },
        path: {
            NL: '/share/:dynamicLink/',
            EN: '/share/:dynamicLink/',
        },
        component: ShareContainer
    }
];
