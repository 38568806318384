import { testimonialActions } from "./testimonial.action.types";
import { Map, List } from 'immutable';
import { accountState } from "../account/account.reducer";

export const testimonialState = new Map({
  testimonialsPending: false,
  testimonialsError: null,
  testimonials: new List([])
});

export const testimonialReducer = (state = testimonialState, action) => {
  switch (action.type) {
    case testimonialActions.GET_TESTIMONALS_PENDING:
      return state
        .set('testimonialsPending', true);
    case testimonialActions.GET_TESTIMONALS_SUCCESS:
      return state
        .set('testimonialsPending', false)
        .set('testimonials', new List(action.testimonials));
    case testimonialActions.GET_TESTIMONALS_ERROR:
      return state
        .set('testimonialsPending', false)
        .set('testimonialsError', action.error);
    case "FLUSH":
      return testimonialState;
    default:
      return state;
  }
};
