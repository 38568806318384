import { blogActions } from "./blog.action.types";
import { Map, List } from 'immutable';
import { accountState } from "../account/account.reducer";


export const blogState = new Map({
  postsPending: false,
  postsError: null,
  posts: new List([])
});

export const blogReducer = (state = blogState, action) => {
  switch (action.type) {
    case blogActions.GET_POSTS_PENDING:
      return state
        .set('postsPending', true);
    case blogActions.GET_POSTS_SUCCESS:
      return state
        .set('posts', action.posts)
        .set('postsPending', false);
    case blogActions.GET_POSTS_ERROR:
      return state
        .set('postsError', action.error)
        .set('postsPending', false);
    case "FLUSH":
      return blogState;
    default:
      return state;
  }
};
