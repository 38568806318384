import { combineReducers } from 'redux';

// App imports
import { accountReducer } from "./account/account.reducer";
import { clientReducer } from "./client/client.reducer";
import { teamReducer } from "./team/team.reducer";
import { newsReducer } from "./news/news.reducer";
import { faqReducer } from "./faq/faq.reducer";
import { testimonialReducer } from "./testimonial/testimonial.reducer";
import { jobReducer } from './job/job.reducer';
import { blogReducer } from "./blog/blog.reducer";
import { contactReducer } from "./contact/contact.reducer";

// Add the possibilty to add additional reducers specific to each Platform (EG: react-native, react)
export const initReducer = (platformReducers = {}) => combineReducers({
  // App reducers
  account: accountReducer,
  client: clientReducer,
  team: teamReducer,
  news: newsReducer,
  faq: faqReducer,
  testimonial: testimonialReducer,
  blog: blogReducer,
  job: jobReducer,
  contact: contactReducer,

  ...platformReducers
});

