import { Map, List } from 'immutable';
import { teamActions } from "./team.action.types";

export const teamState = new Map({
  membersPending: false,
  membersError: null,
  members: new List([])
});

export const teamReducer = (state = teamState, action) => {
  switch (action.type) {
    case teamActions.GET_MEMBERS_PENDING:
      return state
        .set('membersPending', true);
    case teamActions.GET_MEMBERS_SUCCESS:
      return state
        .set('membersPending', false)
        .set('members', action.members);
    case teamActions.GET_MEMBERS_ERROR:
      return state
        .set('membersPending', false)
        .set('membersError', action.error);
    case "FLUSH":
      return teamState;
    default:
      return state;
  }
};
