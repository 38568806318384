import { accountActionTypes } from "./account.action.types";
import { Map, List } from 'immutable';

export const accountState = new Map({
  travelerGroupsPending: false,
  travelerGroupsError: null,
  travelerGroups: new List([]),

  travelerGroupPending: false,
  travelerGroupError: null,
  travelerGroup: {
    name: null,
    active_memberships: [],
  },

  signupPending: false,
  signupError: null,
  signupSteps: [
    {
      active: true,
      completed: false,
      key: "account",
      name: "Account details",
      title: "First create a Tranzer account",
      subTitle: "After you created your account, you receive an email.",
    },
    {
      active: false,
      completed: false,
      key: "policy",
      name: "Review policy",
      title: "Review and accept our policy",
      subTitle: "Once the policy is accepted add your companies payment option.",
    },
    {
      active: false,
      completed: false,
      key: "bank_account",
      name: "Payment method",
      title: "Transfer €0,01 via the bank account your travelers can travel",
      subTitle: "If the payment is successful you can add travelers for your company",
    }
  ],

  loginPending: false,
  loginError: null,
  tokens: {},

  profilePending: false,
  profileError: null,
  profile: {}
});

export const accountReducer = (state = accountState, action) => {
  switch (action.type) {
    case accountActionTypes.LOGIN_PENDING:
      return state.set('loginPending', true);
    case accountActionTypes.LOGIN_SUCCESS:
      return state
        .set('loginPending', false)
        .set('tokens', action.tokens);
    case accountActionTypes.LOGIN_ERROR:
      return state
        .set('loginPending', false)
        .set('loginError', action.error);

    // Store the signup steps so we can return later on in the flow in case of browser exit
    case accountActionTypes.UPDATE_SIGNUP_STEPS:
      return state.set('signupSteps', action.signupSteps);

    case accountActionTypes.SIGNUP_PENDING:
      return state.set('signupPending', true);
    case accountActionTypes.SIGNUP_SUCCESS:
      return state
        .set('signupPending', false)
        .set('signupError', null);
    case accountActionTypes.SIGNUP_ERROR:
      return state
        .set('signupPending', false)
        .set('signupError', action.error);

    case accountActionTypes.GET_PROFILE_PENDING:
      return state
        .set('profilePending', true);
    case accountActionTypes.GET_PROFILE_SUCCESS:
      return state
        .set('profilePending', false)
        .set('profile', action.profile);
    case accountActionTypes.GET_PROFILE_ERROR:
      return state
        .set('profilePending', false)
        .set('profileError', action.error);

    case accountActionTypes.UPDATE_PROFILE_PENDING:
      return state
        .set('profilePending', true);
    case accountActionTypes.UPDATE_PROFILE_SUCCESS:
      return state
        .set('profilePending', false)
        .set('profile', action.profile);
    case accountActionTypes.UPDATE_PROFILE_ERROR:
      return state
        .set('profilePending', false)
        .set('profileError', action.error);


    case accountActionTypes.ADD_TRAVELER_GROUP_PENDING:
      return state
        .set('travelerGroupPending', true);
    case accountActionTypes.ADD_TRAVELER_GROUP_SUCCESS:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroup', action.travelerGroup);
    case accountActionTypes.ADD_TRAVELER_GROUP_ERROR:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroupError', action.error);

    case accountActionTypes.UPDATE_TRAVELER_GROUP_PENDING:
      return state
        .set('travelerGroupPending', true);
    case accountActionTypes.UPDATE_TRAVELER_GROUP_SUCCESS:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroup', action.travelerGroup);
    case accountActionTypes.UPDATE_TRAVELER_GROUP_ERROR:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroupError', action.error);

    case accountActionTypes.DELETE_TRAVELER_GROUP_PENDING:
      return state
        .set('travelerGroupsPending', true);
    case accountActionTypes.DELETE_TRAVELER_GROUP_SUCCESS:
      return state
        .set('travelerGroupsPending', false)
        .set('travelerGroups', action.travelerGroups);
    case accountActionTypes.DELETE_TRAVELER_GROUP_ERROR:
      return state
        .set('travelerGroupsPending', false)
        .set('travelerGroupsError', action.error);

    case accountActionTypes.GET_TRAVELER_GROUPS_PENDING:
      return state.set('travelerGroupsPending', true);
    case accountActionTypes.GET_TRAVELER_GROUPS_SUCCESS:
      return state
        .set('travelerGroupsPending', false)
        .set('travelerGroups', action.travelerGroups);
    case accountActionTypes.GET_TRAVELER_GROUPS_ERROR:
      return state
        .set('travelerGroupsPending', false)
        .set('travelerGroupsError', action.error);

    case accountActionTypes.GET_TRAVELER_GROUP_PENDING:
      return state.set('travelerGroupPending', true);
    case accountActionTypes.GET_TRAVELER_GROUP_SUCCESS:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroup', action.travelerGroup);
    case accountActionTypes.GET_TRAVELER_GROUP_ERROR:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroupError', action.error);

    case accountActionTypes.UPDATE_TRAVELERS_PENDING:
      return state
        .set('travelerGroupPending', true);
    case accountActionTypes.UPDATE_TRAVELERS_SUCCESS:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroup', action.travelerGroup);
    case accountActionTypes.UPDATE_TRAVELERS_ERROR:
      return state
        .set('travelerGroupPending', false)
        .set('travelerGroupError', action.error);

    case accountActionTypes.REMOVE_SEPA:
      return state
        .set('travelerGroup', action.travelerGroup);

    case accountActionTypes.LOGOUT_USER:
      return accountState;

    case "FLUSH":
      return accountState;

    default:
      return state;
  }
};
