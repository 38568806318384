import { Iterable } from 'immutable';
import React from 'react';

export const toJS = WrappedComponent => wrappedComponentProps => {
  const KEY = 0;
  const VALUE = 1;

  const propsJS = Object.entries(
    wrappedComponentProps
  ).reduce((newProps, wrappedComponentProp) => {
    newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(
      wrappedComponentProp[VALUE]
    )
      ? wrappedComponentProp[VALUE].toJS()
      : wrappedComponentProp[VALUE];
    return newProps
  }, {});

  return <WrappedComponent {...propsJS} />
};

export const userAgent = () => {
  return navigator.userAgent;
};


export const platform = () => {
  let platform = '';

  if (/Android/i.test(window.navigator.userAgent)) {
    platform = 'android';
  } else if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
    platform = 'ios'
  } else {
    platform = 'desktop'
  }
  // Check if device is mobile or ios
  return platform;
};

export const emailIsValid = (email) => {
  if (email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
  } else {
    return false;
  }
};


export const compareVersion = (version1, version2) => {
  let version1Array = version1 ? version1.split('.') : '0.0.0'.split('.');
  let version2Array = version2 ? version2.split('.') : '0.0.0'.split('.');

  let version1Newer = {
    major: false,
    minor: false,
    build: false,
  };

  let version2Newer = {
    major: false,
    minor: false,
    build: false,
  };

  let buildIndex = {
    0: 'major',
    1: 'minor',
    2: 'build'
  };

  version1Array.map((version, index) => {
    if (parseInt(version2Array[index]) > parseInt(version)) {
      version2Newer[buildIndex[index]] = true;
    } else if (version > version2Array[index]) {
      version1Newer[buildIndex[index]] = true;
    }
  });

  return {
    version1Newer: version1Newer,
    version2Newer: version2Newer
  }
};
