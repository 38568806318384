import { contactActions } from "./contact.action.types";
import { Map } from 'immutable';
import { accountState } from "../account/account.reducer";

export const contactState = new Map({
  contactFormPending: false,
  contactFormError: null,
});

export const contactReducer = (state = contactState, action) => {
  switch (action.type) {
    case contactActions.SEND_CONTACT_FORM_PENDING:
      return state
        .set('contactFormPending', true);
    case contactActions.SEND_CONTACT_FORM_SUCCESS:
      return state
        .set('contactFormPending', false);
    case contactActions.SEND_CONTACT_FORM_ERROR:
      return state
        .set('contactFormPending', false)
        .set('contactFormError', action.error);
    case "FLUSH":
      return contactState;
    default:
      return state;
  }
};
