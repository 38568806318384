import { faqActions } from "./faq.action.types";
import { Map, List } from 'immutable';
import { accountState } from "../account/account.reducer";

export const faqState = new Map({
  qaPending: false,
  qaError: null,
  qa: new List([])
});

export const faqReducer = (state = faqState, action) => {
  switch (action.type) {
    case faqActions.GET_FAQ_PENDING:
      return state
        .set('qaPending', true);
    case faqActions.GET_FAQ_SUCCESS:
      return state
        .set('qa', action.qa)
        .set('qaPending', false);
    case faqActions.GET_FAQ_ERROR:
      return state
        .set('qaError', action.error)
        .set('qaPending', false);
    case "FLUSH":
      return faqState;
    default:
      return state;
  }
};
