import { Instagram, Twitter, Facebook, Linkedin } from 'react-feather';
import { i18n } from "../../lib/i18n";
import PropTypes from 'prop-types';
import React from 'react';

import { track } from "../../lib/tracking";

// Stylesheets
import colors from '../../colors.scss';
import './download.component.scss';

export class DownloadComponent extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    return {props, state};
  }

  static propTypes = {
    history: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render = () => (
    <div className="downloadComponent">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <h1 className="downloadPayOff">
              {i18n.translate('DOWNLOAD_PAY_OFF')}
            </h1>
            <div className="storeButtons">
              <img className="storeButton" alt="AppStore" src={require('./res/app-store.png')} onClick={() => this.redirectStore('ios')}/>
              <img className="storeButton" alt="PlayStore" src={require('./res/google-play.png')} onClick={() => this.redirectStore('android')}/>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <h1 className="downloadPayOff">{i18n.translate('DOWNLOAD_MAKE_US_POPULAR')}</h1>
            <div className="socialContainer">
              <div className="socialIcon" onClick={() => this.redirectFacebook()}>
                <Facebook color={colors.primary}/>
              </div>
              <div className="socialIcon" onClick={() => this.redirectInstagram()}>
                <Instagram color={colors.primary}/>
              </div>
              <div className="socialIcon" onClick={() => this.redirectTwitter()}>
                <Twitter color={colors.primary}/>
              </div>
              <div className="socialIcon" onClick={() => this.redirectLinkedIn()}>
                <Linkedin color={colors.primary}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  redirectStore = (platform, {history} = this.props) => {
    history.push(`/store/${platform}`);
  };

  redirectFacebook = () => {
    track('redirectFacebook');
    window.open('https://www.facebook.com/Tranzer.nl/', '_blank');
  };

  redirectInstagram = () => {
    track('redirectInstagram');
    window.open('https://www.instagram.com/tranzer_nl/', '_blank');
  };

  redirectTwitter = () => {
    track('redirectTwitter');
    window.open('https://twitter.com/Tranzer_nl', '_blank');
  };

  redirectLinkedIn = () => {
    track('redirectLinkedIn');
    window.open('https://www.linkedin.com/company/tranzer/', '_blank');
  };
}
