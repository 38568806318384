import { faqActions } from "./faq.action.types";
import { api } from "../../lib/api";

export const getQuestions = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: faqActions.GET_FAQ_PENDING
      });
      let res = await api.getCMSContent('/tables/faq/rows/');
      dispatch({
        type: faqActions.GET_FAQ_SUCCESS,
        qa: res.data
      });
    } catch (error) {
      dispatch({
        type: faqActions.GET_FAQ_ERROR,
        error: error
      });
    }
  }
};
