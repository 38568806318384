import { clientActionTypes } from "./client.action.types";
import { Map, List, fromJS } from 'immutable';

export const clientState = new Map({
  storeLoading: true,

  categories: new List([]),

  dynamicLinkPending: false,
  dynamicLinkError: null,
  dynamicLink: null,

  pirate: new Map({
    uuid: null,
  }),

  loginOriginPath: '/',

  GDPR: new Map({
    consent: false,
    cookies: {
      functional: true,
      statistics: false,
      performance: false,
      targeting: false,
    }
  }),

  infoPending: false,
  infoError: null,
  info: new Map({
    userAgent: null,
    location: new Map({
      countryCode: "NL"
    })
  }),
  version: null,

  termsAndConditionsPending: false,
  termsAndConditionsError: null,
  termsAndConditions: {
    revision_date: null,
    version: null,
    translations: {
      data: []
    }
  },

  privacyStatementPending: false,
  privacyStatementError: null,
  privacyStatement: {
    revision_date: null,
    version: null,
    translations: {
      data: []
    }
  },
});

export const clientReducer = (state = clientState, action) => {
  switch (action.type) {
    case clientActionTypes.INIT_PIRATE:
      return state.setIn(['pirate', 'uuid'], action.uuid);

    case clientActionTypes.CREATE_DYNAMIC_LINK_PENDING:
      return state.set('dynamicLinkPending', true);
    case clientActionTypes.CREATE_DYNAMIC_LINK_SUCCESS:
      return state.merge(fromJS({dynamicLinkPending: false, dynamicLink: action.link}));
    case clientActionTypes.CREATE_DYNAMIC_LINK_ERROR:
      return state.merge(fromJS({dynamicLinkPending: false, error: action.error}));


    case clientActionTypes.GET_LOCATION_INFO_PENDING:
      return state.set('infoPending', true);
    case clientActionTypes.GET_LOCATION_INFO_SUCCESS:
      return state.merge(fromJS({infoPending: false, info: {location: action.location}}));
    case clientActionTypes.GET_LOCATION_INFO_ERROR:
      return state.merge(fromJS({infoPending: false, infoError: action.error}));


    case clientActionTypes.SET_COUNTRY_CODE:
      return state.setIn(['info', 'location', 'countryCode'], action.countryCode);

    case clientActionTypes.SET_GDPR_CONSENT:
      return state
        .setIn(['GDPR', 'consent'], true)
        .setIn(['GDPR', 'cookies'], action.cookieSettings);

    case clientActionTypes.GET_PRIVACY_STATEMENT_PENDING:
      return state
        .set('privacyStatementPending', true);
    case clientActionTypes.GET_PRIVACY_STATEMENT_SUCCESS:
      return state
        .set('privacyStatementPending', false)
        .set('privacyStatement', action.privacyStatement);
    case clientActionTypes.GET_PRIVACY_STATEMENT_ERROR:
      return state
        .set('privacyStatementPending', false)
        .set('privacyStatementError', action.error);

    case clientActionTypes.GET_TERMS_AND_CONDITIONS_PENDING:
      return state
        .set('termsAndConditionsPending', true);
    case clientActionTypes.GET_TERMS_AND_CONDITIONS_SUCCESS:
      return state
        .set('termsAndConditionsPending', false)
        .set('termsAndConditions', action.termsAndConditions);
    case clientActionTypes.GET_TERMS_AND_CONDITIONS_ERROR:
      return state
        .set('termsAndConditionsPending', false)
        .set('termsAndConditionbsError', action.error);
    case clientActionTypes.SET_VERSION:
      return state.set('version', action.version);
    case "FLUSH":
      return clientState;
    default:
      return state;
  }
};
