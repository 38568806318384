import PropTypes from 'prop-types';
import React from 'react';

// Stylesheets
import colors from '../../colors.scss';
import './map.component.scss';

const geoJsonPoints = [];

const fitBounds = (features, map) => {
  let bounds = new window.google.maps.LatLngBounds();

  features.map((feature) => {
    return bounds.extend(feature.getGeometry().get());
  });

  map.fitBounds(bounds);
};

export class Map extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.geoJsonMarkersURL && nextProps.geoJsonMarkersURL !== prevState.geoJsonMarkersURL) {

      // Remove the markers from the previously added dataLayer
      geoJsonPoints.forEach((feature) => {
        prevState.map.data.remove(feature);
      });

      // add the new markers to the map
      prevState.map.data.loadGeoJson(nextProps.geoJsonMarkersURL, null, (features) => {
        fitBounds(features, prevState.map);
      });

      return {
        ...prevState,
      }
    } else {
      return prevState;
    }
  }

  static propTypes = {
    options: PropTypes.any,
    height: PropTypes.number,
    onClickFeature: PropTypes.func,

    geoJsonURL: PropTypes.string,
    geoJsonMarkersURL: PropTypes.string,
  };

  static defaultProps = {
    height: 400,
    options: {},
    onClickFeature: () => console.log("Default onClickFeature"),

    geoJsonURL: null,
    geoJsonMarkersURL: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      map: null,
    };
  }

  componentDidMount({options, geoJsonURL, onClickFeature} = this.props) {
    this.setState({
      map: new window.google.maps.Map(this.mapElement, {
        center: {lat: -34.397, lng: 150.644},
        zoom: 8,
        ...options,
      })
    }, () => {
      this.state.map.data.loadGeoJson(geoJsonURL, null, () => {
        this.state.map.data.addListener('addfeature', (event) => {
          geoJsonPoints.push(event.feature);
        });
      });

      this.state.map.data.setStyle((feature) => ({
        fillColor: colors.primary,
        fillOpacity: feature.getProperty('value') / 150 || 0,
        strokeColor: colors.primary,
        strokeWeight: .5,
        // animation: window.google.maps.Animation.DROP,
      }));

      this.state.map.data.addListener('click', (event) => {
        onClickFeature(event.feature);
      });
    });
  }

  render = ({defaultZoom, options, height} = this.props) => (
    <div className="mapContainer" style={{height: height}}>
      <div ref={(ref) => this.mapElement = ref} className="map"/>
    </div>
  );
}
