import { BeatLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import React from 'react';

import './button.component.scss';

export class Button extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.disabled !== prevState.disabled) {
      prevState.buttonRef.classList.toggle('disabled');
      return {
        ...prevState,
        disabled: nextProps.disabled,
      }
    }
    return prevState;
  };

  static propTypes = {
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    outline: PropTypes.bool,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
  };

  static defaultProps = {
    backgroundColor: '#000',
    color: '#fff',
    title: 'default title',
    outline: false,
    onClick: () => console.log("default button onClick"),
    isLoading: false,
    disabled: false,
    iconLeft: null,
    iconRight: null,
  };

  constructor(props) {
    super(props);

    this.styles = {
      backgroundColor: props.outline ? 'transparent' : props.backgroundColor,
      borderWidth: props.outline ? 2 : 0,
      borderColor: props.outline ? props.color : 'transparent',
      borderStyle: props.outline ? 'solid' : 'none'
    };

    this.state = {
      disabled: props.disabled,
      buttonRef: null,
    }
  }

  componentDidMount() {
    if (this.props.className) {
      this.buttonContainer.classList.add(this.props.className)
    }
    this.setState({buttonRef: this.buttonContainer});
    // this.props.disabled ? this.buttonContainer.classList.toggle('disabled') : null;
  }

  render = ({backgroundColor, outline, color, title, style, onClick, iconLeft, iconRight, isLoading} = this.props) => (
    <div
      className="buttonContainer"
      ref={(ref) => this.buttonContainer = ref}
      style={{...this.styles, ...style}}
      onClick={!this.state.disabled ? onClick : () => console.log("Button is disabled.")}>

      {iconLeft && (
        <div className="buttonIconLeft">
          {iconLeft}
        </div>
      )}

      {!isLoading && (
        <p className="buttonText" style={{color: color}}>{title}</p>
      )}

      {iconRight && (
        <div className="buttonIconRight">
          {iconRight}
        </div>
      )}

      {isLoading && (
        <BeatLoader color="rgba(255, 255, 255, .8)" size={10}/>
      )}

    </div>
  )
}
