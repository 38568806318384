import moment from 'moment/min/moment-with-locales';
import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { DownloadComponent } from "../../components/download/download.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Section, Title } from "../../components/section";

// Redux
import { getPosts } from "../../store/blog/blog.actions";
import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './blog.container.scss';

class BlogContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.getPosts();
  }

  render = ({blog, client, history} = this.props) => (
    <div className="blogContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
      <Section name="news">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title
                title={i18n.translate('BLOG_TITLE')}
                subTitle={i18n.translate('BLOG_SUBTITLE')}
                sectionColor={colors.c1}
                color={colors.primary}/>
            </div>
          </div>

          <div className="row">
            {blog.posts.map((post, index) => (
              <div key={index} className="col-sm-12 col-md-4 col-lg-4 col-xl-4 blogPostContainer">
                {post.image && (
                  <div className="imageContainer" onClick={() => this.readPost(post)}>
                    <div className="image" style={{backgroundImage: `url(${post.image.data.url})`}}/>
                  </div>
                )}
                <h1 className="title">{post.translations.data.find((post) => post.language_code === client.info.location.countryCode).title}</h1>
                <h3 className="timestamp">
                  {moment(post.translations.data.find((post) => post.language_code === client.info.location.countryCode).date_created).format("D MMMM YYYY")}
                </h3>
                <div className="postContainer" dangerouslySetInnerHTML={{__html: post.translations.data.find((post) => post.language_code === client.info.location.countryCode).intro}}/>
                <div className="postLink">
                  <p>Read more</p>
                </div>
              </div>
            ))}
          </div>

        </div>
      </Section>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );

  readPost = (post, {client} = this.props) => {
    this.props.history.push(`/blog/${post.slug}`, {post: post.translations.data.find((post) => post.language_code === client.info.location.countryCode)})
  }

}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    blog: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPosts: () => dispatch(getPosts())
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(BlogContainer));

export { connectedContainer as BlogContainer };
