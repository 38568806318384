import { Element } from 'react-scroll'
import colors from '../../colors.scss';
import PropTypes from 'prop-types';
import React from 'react';

import './section.component.scss';

export class Section extends React.PureComponent {
  static propTypes = {
    backgroundColor: PropTypes.string,
    name: PropTypes.string.isRequired,
    children: PropTypes.any,
    props: PropTypes.any,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: ""
  };

  render = ({name, children, props, backgroundColor, className} = this.props) => (
    <Element className={`section ${className}`} name={name} {...props} style={{backgroundColor: backgroundColor}}>
      {children}
    </Element>
  )
}

export class Title extends React.PureComponent {
  static propTypes = {
    sectionColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    subTitleColor: PropTypes.string,
    props: PropTypes.any,
    className: PropTypes.string,
  };

  static defaultProps = {
    sectionColor: colors.c1,
    backgroundColor: colors.primary,
    color: '#fff',
    subTitleColor: '#525459',
    className: ''
  };


  render = ({title, subTitle, color, backgroundColor, sectionColor, subTitleColor, props, className} = this.props) => (
    <div className={`sectionTitleContainer ${className}`}>
      <div className="sectionTitle" style={{color: color}} {...props}>
        <h1 className="title" style={{color: color}}>{title}</h1>
        <h2 className="subTitle" style={{color: subTitleColor}}>{subTitle}</h2>
      </div>
    </div>
  )
}
