import { platform } from "../../lib/helpers";
import { BeatLoader } from 'react-spinners';
import FireBase from 'firebase';
import React from 'react';

// Components
import { Section, Title } from "../../components/section";
import { Button } from "../../components/button";

// Redux
import { createDynamicLink } from '../../store/client/client.actions';
import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './store.container.scss';


class StoreContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      platform: this.platform(),
      pirateUUID: null,
      showButtons: false,
      redirectTitle: 'Redirecting',
      redirectSubtitle: 'We now redirect you to the store matching your device'
    };

    this.urls = {
      ios: 'https://itunes.apple.com/nl/app/tranzer/id1204210982?l=en&mt=8',
      android: 'https://play.google.com/store/apps/details?id=nl.data_lab.tranzer&hl=en'
    };

    // Set the configuration for your app
    let config = {
      apiKey: "AIzaSyAtjXPzA8adaejVohVj-bdReGVqN0idbh8",
      authDomain: "tranzer-7d344.firebaseapp.com",
      databaseURL: "https://tranzer-7d344.firebaseio.com",
      projectId: "tranzer-7d344",
      storageBucket: "tranzer-x.appspot.com",
      messagingSenderId: "298071319819"
    };

    if (FireBase.apps.length === 0) {
      console.log("INIT FIREBASE");
      FireBase.initializeApp(config);
    }

    // Generate pirateUUID and create dynamic link for iOS and Android only. Desktop show the buttons after timeout.
    let pirateUUID = this.pirateUUID();
    if (platform() !== 'desktop') {
      this.props.createDynamicLink(pirateUUID)
        .then((link) => {
          console.log('link', link);
          window.open(link.shortLink, '_self');
        });
    } else {
      setTimeout(() => {
        this.setState({
          showButtons: !this.state.showButtons,
          redirectTitle: 'Manual download',
          redirectSubtitle: 'We could not find the store matching your device, are you on desktop?'
        });
      }, 1000)
    }
  }

  render = () => (
    <div className="storeContainer">
      <Section name="faq" className="no-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title
                className="no-padding"
                title={this.state.redirectTitle}
                subTitle={this.state.redirectSubtitle}
                sectionColor={colors.c1}
                color={colors.primary}/>
            </div>
          </div>
        </div>
      </Section>
      {!this.state.showButtons && (
        <BeatLoader color={colors.primary} size={10}/>
      )}

      {this.state.showButtons && (
        <div className="manualDownload">
          <div className="storeButtons">
            <img className="storeButton" alt="AppStore" src={require('./res/app-store.png')} onClick={() => this.redirectStore('ios')}/>
            <img className="storeButton" alt="PlayStore" src={require('./res/google-play.png')} onClick={() => this.redirectStore('android')}/>
          </div>
          <div className="back">
            <h3 className="center">or</h3>
            <Button title="Back to website" backgroundColor={colors.primary} onClick={() => this.props.history.push('/')}/>
          </div>
        </div>
      )}
    </div>
  );

  redirectStore = (platform, {history} = this.props) => {
    window.open(this.urls[platform], '_blank');
    history.push('/');
  };

  platform = () => {
    let platform = '';

    if (/Android/i.test(window.navigator.userAgent)) {
      platform = 'android';
      // else if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
    } else if (/iPhone|iPod/i.test(window.navigator.userAgent)) {
      platform = 'ios'
    } else {
      platform = 'desktop'
    }
    // Check if device is mobile or ios
    return platform;
  };

  pirateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 || 0x8);
      return v.toString(16);
    });
  };
}


const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDynamicLink: (pirateUUID) => dispatch(createDynamicLink(pirateUUID))
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoreContainer);

export { connectedContainer as StoreContainer };
