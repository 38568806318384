export const environment = {
  development: {
    apiUrl: 'http://localhost:8000/api/v1',
    contentUrl: 'https://cms.tranzer.com/api/1.1',
    storageUrl: 'https://cms.tranzer.com',
    siteUrl: 'http://localhost:3000',
    clientID: '1KsfQqSFnUyW2x7k7velGAjdLBnVuGXzNHEXe7FK',
    clientSecret: '9O9CLCOsJSZ9nNlMa2iRhhv3eYHDWBv5U0Ibsr2aghGF90pnnsTrjP1rHyRoVeVu1Vv7P2ws7pdvgb1FYbWL5G9anIU6sZH28tQiVKEIgbNF4JLOt596cPZIsAHNc9r7',
    dynamicLink: {
      android: {
        androidPackageName: 'tranzer.internal'
      },
      ios: {
        iosBundleId: "com.tranzer.dev"
      }
    },
  },
  staging: {
    apiUrl: 'https://dev.tranzer.com/api/v1',
    contentUrl: 'https://cms.tranzer.com/api/1.1',
    storageUrl: 'https://cms.tranzer.com',
    siteUrl: 'https://staging.tranzer.com',
    clientID: '1KsfQqSFnUyW2x7k7velGAjdLBnVuGXzNHEXe7FK',
    clientSecret: '9O9CLCOsJSZ9nNlMa2iRhhv3eYHDWBv5U0Ibsr2aghGF90pnnsTrjP1rHyRoVeVu1Vv7P2ws7pdvgb1FYbWL5G9anIU6sZH28tQiVKEIgbNF4JLOt596cPZIsAHNc9r7',
    dynamicLink: {
      android: {
        androidPackageName: 'tranzer.internal'
      },
      ios: {
        iosBundleId: "com.tranzer.dev"
      }
    },
  },
  production: {
    apiUrl: 'https://api.tranzer.com/api/v1',
    contentUrl: 'https://cms.tranzer.com/api/1.1',
    storageUrl: 'https://cms.tranzer.com',
    siteUrl: 'https://tranzer.com',
    clientID: '1KsfQqSFnUyW2x7k7velGAjdLBnVuGXzNHEXe7FK',
    clientSecret: '9O9CLCOsJSZ9nNlMa2iRhhv3eYHDWBv5U0Ibsr2aghGF90pnnsTrjP1rHyRoVeVu1Vv7P2ws7pdvgb1FYbWL5G9anIU6sZH28tQiVKEIgbNF4JLOt596cPZIsAHNc9r7',
    dynamicLink: {
      android: {
        androidPackageName: 'nl.data_lab.tranzer'
      },
      ios: {
        iosBundleId: 'nl.data-lab.tranzer'
      }
    },
  },
};
