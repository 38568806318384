import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { DownloadComponent } from "../../components/download/download.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Section, Title } from "../../components/section";

// Redux
import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './jobs.container.scss';



class JobContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      offer: (props.location.state && props.location.state.offer) || null,
    };

    console.log(props);
    if (!this.state.offer) {
      props.history.push(props.match.url.replace(props.match.params.offer, ''));
    }
  }

  render = ({history} = this.props) => (
    <div className="jobContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
      <Section name="team">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('JOBS_TITLE')}
                     subTitle={i18n.translate('JOBS_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: this.state.offer}}/>
          </div>
        </div>
      </Section>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    job: state.job,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(JobContainer));

export { connectedContainer as JobContainer };
