import { ChevronDown } from 'react-feather';
import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Section, Title } from "../../components/section";
import { DownloadComponent } from "../../components/download/download.component";

// Redux
import { getQuestions } from "../../store/faq/faq.actions";
import { connect } from 'react-redux';

// Stylesheet
import colors from '../../colors.scss';
import './faq.container.scss';


class FAQContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.getQuestions();

    this.state = {
      activeIndex: null,
    }
  }

  render = ({faq, client, history} = this.props) => (
    <div className="faqContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
      <Section name="faq">
        <div className="container questionsContainer">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('FAQ_TITLE')}
                     subTitle={i18n.translate('FAQ_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2"/>
            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
              {faq.qa.map((qa, index) => (
                <div key={index} className="qaContainer">
                  <div className="questionContainer" onClick={() => this.toggleAnswer(index)}>
                    <h1 className="question">{qa.translations.data.find((qa) => qa.language_code === client.info.location.countryCode).question}</h1>
                    <div className="icon" ref={`iconRef-${index}`}>
                      <ChevronDown color={colors.primary}/>
                    </div>
                  </div>
                  <div className="answer"
                       ref={`answerRef-${index}`}
                       dangerouslySetInnerHTML={{__html: qa.translations.data.find((qa) => qa.language_code === client.info.location.countryCode).answer}}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );

  toggleAnswer = (index) => {
    this.props.faq.qa.map((qa, qaIndex) => {
      if (qaIndex !== index) {
        this.refs[`answerRef-${qaIndex}`].classList.remove('visible');
        this.refs[`iconRef-${qaIndex}`].classList.remove('open');
      }
    });

    this.refs[`answerRef-${index}`].classList.toggle('visible');
    this.refs[`iconRef-${index}`].classList.toggle('open');
  }
}


const mapStateToProps = (state) => {
  return {
    faq: state.faq,
    client: state.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuestions: () => dispatch(getQuestions()),
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(FAQContainer));

export { connectedContainer as FAQContainer };
