import { environment } from "./enviroment";
import { Toast } from "./toast/toast";
import { store } from "../app";
import { i18n } from "./i18n";

class Api {
  constructor(mode) {
    this.environment = environment[mode];
    this.tokens = {};
  }

  getCustomURL = (url = '') => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => this.handleResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };

  getCMSContent = (postfix = '') => {
    return new Promise((resolve, reject) => {
      fetch(`${this.environment.contentUrl}${postfix}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => this.handleResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };

  login = (postfix = '', payload = {}) => {
    return new Promise((resolve, reject) => {
      fetch(`${this.environment.apiUrl}${postfix}`, {
        method: "POST",
        body: this.transformRequest(payload),
        headers: this.buildHeaders({encoded: true})
      }).then((response) => this.handleLoginResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };

  signup = (postfix = '', payload = {}) => {
    return new Promise((resolve, reject) => {
      fetch(`${this.environment.apiUrl}${postfix}`, {
        method: "POST",
        body: this.transformRequest(payload),
        headers: this.buildHeaders({encoded: true})
      }).then((response) => this.handleLoginResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };

  get = (postfix = '') => {
    return new Promise((resolve, reject) => {
      fetch(`${this.environment.apiUrl}${postfix}`, {
        method: "GET",
        headers: this.buildHeaders({encoded: false})
      })
        .then((response) => this.handleResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };

  put = (postfix = '', payload = {}) => {
    return new Promise((resolve, reject) => {
      fetch(`${this.environment.apiUrl}${postfix}`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: this.buildHeaders({encoded: false})
      })
        .then((response) => this.handleResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };

  post = (postfix = '', payload = {}) => {
    return new Promise((resolve, reject) => {
      fetch(`${this.environment.apiUrl}${postfix}`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: this.buildHeaders({encoded: false})
      })
        .then((response) => this.handleResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };

  delete = (postfix = '') => {
    return new Promise((resolve, reject) => {
      fetch(`${this.environment.apiUrl}${postfix}`, {
        method: "DELETE",
        headers: this.buildHeaders({encoded: false})
      })
        .then((response) => this.handleResponse(response, resolve, reject))
        .catch((error) => this.handleError(error, reject));
    });
  };


  transformRequest = (object) => {
    let str = [];
    Object.keys(object).map((key) => {
      str.push(encodeURIComponent(key) + '=' + encodeURIComponent(object[key]));
    });
    return str.join("&")
  };

  buildHeaders = ({encoded}) => {
    let headers = new Headers();
    let clientState = store.getState().client.toJS();

    // Add Content-Type based on encoding
    encoded ? headers.append('Content-Type', 'application/x-www-form-urlencoded') : headers.append("Content-Type", "application/json");

    headers.append('Accept-Language', clientState.info.location.countryCode);

    // Add Authorization if tokens are available
    // this.tokens.access_token ? headers.append('Authorization', `Bearer ${this.tokens.access_token}`) : null;

    return headers;
  };

  handleLoginResponse = (response, resolve, reject) => {
    switch (true) {
      case (response.status >= 200 && response.status <= 299):
        return resolve(response.json());
      case (response.status >= 400 && response.status <= 499):
        return this.handleError(response.json(), reject);
      case (response.status >= 500 && response.status <= 599):
        return this.handleError(this.returnNonPromiseError(), reject);
      default:
        return this.handleError(response.json(), reject);
    }
  };

  handleResponse = (response, resolve, reject) => {
    switch (true) {
      case (response.status >= 200 && response.status <= 299):
        return resolve(response.json());
      case (response.status >= 400 && response.status <= 499):
        return this.handleError(response, reject);
      case (response.status >= 500 && response.status <= 599):
        return this.handleError(response, reject);
      default:
        return this.handleError(response, reject);
    }
  };

  handleError = (response, reject) => {
    console.log(response.status);

    if (response.then instanceof Function) {
      return response.then((error) => {
        Toast.show(
          i18n.countryCode === "NL" ? "Er ging iets mis." : "Something went wrong.",
          this.parseError(error)
        );
        reject(this.parseError(error));
      });
    }
  };


  parseError = (error) => {
    const somethingWrongError = 'ERROR_SOMETHING_WRONG';
    const timeOutError = 'ERROR_TIME_OUT';
    console.log('my error', error);


    // let err  = error.then( (value) =>value.error_description);
    // return err;
    if (error.error_description) {
      return error.error_description;
    }
    if (error.password) {
      return error.password[0]
    }
    if (error.message) {
      return error.message
    }
    if (error.detail) {
      return error.detail
    }
    return somethingWrongError
  }
}

console.log(`Running ${process.env.NODE_ENV} mode`);
export const api = new Api(process.env.NODE_ENV); // environment
