export const homeTranslations = {
    HOME_PARALLAX_TITLE: 'Just take the ride!',
    HOME_PARALLAX_SUBTITLE: 'Plan, book and travel with your ticket from the Tranzer app. With Tranzer you can buy tickets in-app for public transport or easily book a taxi. Tranzer can be downloaded for free via iOS and Android.',

    HOME_WHERE_BLOCK_TITLE: 'Where can I use Tranzer?',
    HOME_WHERE_BLOCK_SUBTITLE: 'Tranzer is currently available on train, taxi, tram and bus services.',
    HOME_WHERE_BLOCK_DESCRIPTION: [
        'All national trains in the Netherlands',
        'International trains in Europe',
        'All Connexxion, Breng and Bravo/Hermes busses',
        'GVB busses and trams (excluding night bus)',
        'RET busses and trams (including BOB-bus)',
        'All EBS busses',
        'Several taxi services'
    ],

    HOME_PAY_BLOCK_TITLE: 'How can I pay with Tranzer?',
    HOME_PAY_BLOCK_SUBTITLE: 'All payments are managed via the app, using your preferred method of payment.',
    HOME_PAY_BLOCK_DESCRIPTION: 'When setting-up your account, you can select the payment method you prefer. Tranzer currently supports payment using iDeal or credit card. Save your credit card details in your account to quickly purchase a ticket.',

    HOME_HOW_BLOCK_TITLE: 'How do I use Tranzer?',
    HOME_HOW_BLOCK_SUBTITLE: 'To use Tranzer all you need to do is download the app and set-up an account.',
    HOME_HOW_BLOCK_DESCRIPTION: 'You can enter your destination, select from the public transport options identified by Tranzer that best suits your needs and just take the ride!',

    HOME_CORPORATE_TITLE: "Partners",
    HOME_CORPORATE_SUBTITLE: "Just a few of the organisations in the rapidly expanding Tranzer network.",

    HOME_MAPS_TITLE: 'Available stations',
    HOME_MAPS_SUBTITLE: 'Select a country to see the current available stations'
};
