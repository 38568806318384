import { Facebook, Instagram, Twitter, Linkedin } from 'react-feather';
import { toJS } from "../../lib/helpers";
import { track } from "../../lib/tracking";
import { i18n } from "../../lib/i18n";
import { routes } from "../../routes";
import React from 'react';

// Redux
import { connect } from 'react-redux';

import './footer.component.scss';

class Footer extends React.PureComponent {
  render = ({history, client} = this.props) => (
    <div className="footerComponent">
      <div className="contactSection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="footerCard">
                <h1 className="title">Tranzer</h1>
                <p className="footerParagraph">Stationsplein 61</p>
                <p className="footerParagraph">3818 LE Amersfoort</p>
                <p className="footerParagraph footerLink link" onClick={() => this.redirectEmail()}>
                  support@tranzer.com
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="footerCard">
                <h1 className="title">
                  {i18n.translate('FOOTER_NAVIGATION_TITLE')}
                </h1>
                {routes.filter((route) => route.footerVisible).map((route, index) => (
                  <p key={index} className="footerParagraph footerLink" onClick={() => history.push(route.path[client.info.location.countryCode])}>
                    {route.title[client.info.location.countryCode]}
                  </p>
                ))}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="footerCard">
                <h1 className="title">Social Media</h1>
                <p className="footerParagraph footerLink" onClick={() => this.redirectFacebook()}>
                  <Facebook className="footerIcon" color="#fff" size={20}/> Facebook
                </p>
                <p className="footerParagraph footerLink" onClick={() => this.redirectInstagram()}>
                  <Instagram className="footerIcon" color="#fff" size={20}/> Instagram
                </p>
                <p className="footerParagraph footerLink" onClick={() => this.redirectTwitter()}>
                  <Twitter className="footerIcon" color="#fff" size={20}/> Twitter
                </p>
                <p className="footerParagraph footerLink" onClick={() => this.redirectLinkedIn()}>
                  <Linkedin className="footerIcon" color="#fff" size={20}/> LinkedIn
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyrightSection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img className="logo" alt="Tranzer logo" src={require('./res/logo.png')}/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 right">
              <p className="copyrightLink" onClick={() => this.redirectPrivacyStatement()}>
                {i18n.translate('FOOTER_PRIVACY_STATEMENT')}
              </p>
              <p className="copyrightLink" onClick={() => this.redirectTermsAndConditions()}>
                {i18n.translate('FOOTER_TERMS_AND_CONDITIONS')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  redirectPrivacyStatement = ({history, client} = this.props) => {
    let route = routes.find((route) => route.path.NL.split('/').join('') === 'privacyverklaring');
    history.push(route.path[client.info.location.countryCode]);
  };

  redirectTermsAndConditions = ({history, client} = this.props) => {
    let route = routes.find((route) => route.path.NL.split('/').join('') === 'algemene-voorwaarden');
    history.push(route.path[client.info.location.countryCode]);
  };

  redirectFacebook = () => {
    track('redirectFacebook');
    window.open('https://www.facebook.com/Tranzer.nl', '_blank');
  };

  redirectInstagram = () => {
    track('redirectInstagram');
    window.open('https://www.instagram.com/tranzer_nl/', '_blank')
  };

  redirectTwitter = () => {
    track('redirectTwitter');
    window.open('https://twitter.com/Tranzer_nl/', '_blank')
  };

  redirectLinkedIn = () => {
    track('redirectLinkedIn');
    window.open('https://www.linkedin.com/company/tranzer/', '_blank')
  };

  redirectEmail = () => {
    track('redirectEmail');
    window.open("mailto:support@tranzer.com?subject=Ik heb een vraag.", '_blank')
  };
}

const mapStateToProps = (state) => {
  return {
    client: state.client
  };
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(Footer));

export { connectedContainer as Footer };
