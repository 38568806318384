import { jobActions } from "./job.action.types";
import { api } from "../../lib/api";

export const getJobOffers = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: jobActions.GET_JOB_OFFERS_PENDING,
      });

      let res = await api.getCMSContent('/tables/jobs/rows/');

      dispatch({
        type: jobActions.GET_JOB_OFFERS_SUCCESS,
        offers: res.data
      });
    } catch (error) {
      dispatch({
        type: jobActions.GET_JOB_OFFERS_ERROR,
        error: error,
      });
    }
  };
};
