export const contactTranslations = {
  CONTACT_TITLE: 'Contact',
  CONTACT_SUBTITLE: 'Stuur je feedback of stel een vraag.',

  CONTACT_FORM_FIRST_NAME_PLACEHOLDER: 'Voornaam',
  CONTACT_FORM_LAST_NAME_PLACEHOLDER: 'Achternaam',
  CONTACT_FORM_EMAIL_PLACEHOLDER: 'Email',
  CONTACT_FORM_FEEDBACK_PLACEHOLDER: 'Bericht',

  CONTACT_FORM_SEND_BUTTON_TEXT: 'Verstuur je feedback',

  CONTACT_FORM_SUCCESS_TITLE: 'Feedback verzonden.',
  CONTACT_FORM_SUCCESS_MESSAGE: 'We nemen zo snel mogelijk contact met je op.',

  CONTACT_FORM_ERROR_TITLE: 'Dat ging niet helemaal goed.',
  CONTACT_FORM_ERROR_MESSAGE: 'Een email adres en jou feedback zijn verplicht.',
};
