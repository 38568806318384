import PropTypes from 'prop-types';
import React from "react";

import './parallax.component.scss';

export class Parallax extends React.PureComponent {
  static propTypes = {
    backgroundImage: PropTypes.string,
    fixedHeight: PropTypes.any,
    children: PropTypes.any,
    shader: PropTypes.bool,
  };

  static defaultProps = {
    fixedHeight: null,
    shader: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      positionY: 0,
      height: props.fixedHeight ? props.fixedHeight : 'auto',
      isLoading: true,
    };

  }

  componentDidMount({fixedHeight, shader} = this.props) {
    if (!fixedHeight) {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions.bind(this), false);
    } else {
      this.parallaxImage.classList.add('visible');
    }

    if (shader) {
      this.shader.classList.add('visible');
    }

    window.addEventListener('scroll', this.handleParallax, false);
  }

  componentWillUnmount({fixedHeight} = this.props) {
    if (!fixedHeight) {
      window.removeEventListener('resize', this.updateWindowDimensions.bind(this), false);
    }
    window.removeEventListener('scroll', this.handleParallax, false);
  }

  render = ({backgroundImage, children, fixedHeight} = this.props) => (
    <div
      className="parallaxContainer"
      style={{
        height: this.state.height,
      }}
      ref={(ref) => this.parallaxContainer = ref}>
      <div
        ref={(ref) => this.parallaxImage = ref}
        className="parallaxImage"
        style={{
          backgroundSize: fixedHeight ? 'cover' : 'contain',
          backgroundPositionY: this.state.positionY,
          backgroundImage: `url(${backgroundImage})`
        }}>
        <div ref={(ref) => this.shader = ref} className="shader">
          {children}
        </div>
      </div>
    </div>
  );

  updateWindowDimensions = () => {
    this.imageDimensions(this.props.backgroundImage).then(({width, height}) => {
      let percentage = window.innerWidth / width * 100;
      let imageHeight = height / 100 * percentage;

      this.setState({
        isLoading: false,
        height: imageHeight
      }, () => {
        this.parallaxImage.classList.add('visible');
      });
    })
  };

  imageDimensions = (image) => new Promise((resolve, reject) => {
    let img = new Image();
    img.onerror = reject;
    img.src = image;

    img.onload = () => resolve({width: img.width, height: img.height});
  });


  handleParallax = () => {
    let scrolledHeight = window.pageYOffset;
    let limit = this.parallaxContainer.offsetTop + this.parallaxContainer.offsetHeight;

    if (scrolledHeight > this.parallaxContainer.offsetTop && scrolledHeight <= limit) {
      this.setState({positionY: (scrolledHeight - this.parallaxContainer.offsetTop) / 5});
    }
    else {
      this.setState({positionY: 0});
    }
  };
}
