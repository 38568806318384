import { toJS } from "../../lib/helpers";
import { scroller } from 'react-scroll';
import { i18n } from "../../lib/i18n";
import React from 'react';

// Navigation items. Translate can be done in there
import { routes as routerRoutes } from "../../routes";

// Components
import { Button } from "../button";

// Redux
import { setCountryCode } from "../../store/client/client.actions";
import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './navigation.component.scss';

const toggleIcon = "M115.8 882.7L872.4 882.7C896.2 882.7 915.6 863.3 915.6 839.5 915.6 815.7 896.2 796.4 872.4 796.4L115.8 796.4C91.9 796.4 72.6 815.7 72.6 839.5 72.6 863.3 91.9 882.7 115.8 882.7ZM115.8 543.1L872.4 543.1C896.2 543.1 915.6 523.8 915.6 500 915.6 476.1 896.2 456.8 872.4 456.8L115.8 456.8C91.9 456.8 72.6 476.1 72.6 500 72.6 523.8 91.9 543.1 115.8 543.1ZM115.8 203.6L872.4 203.6C896.2 203.6 915.6 184.2 915.6 160.4 915.6 136.6 896.2 117.3 872.4 117.3L115.8 117.3C91.9 117.3 72.6 136.6 72.6 160.4 72.6 184.2 91.9 203.6 115.8 203.6Z";
const closeIcon = "M907.9 190.3L809.7 92.1 500 401.8 190.3 92.1 92.1 190.3 401.8 500 92.1 809.7 190.3 907.9 500 598.2 809.7 907.9 907.9 809.7 598.2 500Z";

const countries = [
  {countryCode: 'NL', flag: require('./res/NL.png')},
  {countryCode: 'EN', flag: require('./res/EN.png')},
];

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      activeItem: props.history.location.pathname || null,
      routes: routerRoutes.filter((route) => route.visible),

      currentCountry: countries.find((country) => country.countryCode === props.client.info.location.countryCode),
      possibleCountries: countries.filter((country) => country.countryCode !== props.client.info.location.countryCode),
      countryCode: props.client.info.location.countryCode,

      backgroundVisible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
  }

  scrollListener = () => {
    let app = document.getElementById("app");

    if (this.navigationContainer.getBoundingClientRect().y <= 0 && app.getBoundingClientRect().y < 0) {
      this.navigationContainer.classList.add('visible');
    } else {
      this.navigationContainer.classList.remove('visible');
    }
  };

  render = ({history} = this.props, {routes} = this.state) => (
    <div className="navigationContainer" ref={(ref) => this.navigationContainer = ref}>
      <div className="navigationWrapper">
        <div className="logoContainer">
          <img alt="Tranzer logo" onClick={() => history.push('/')} className="logo" src={require('./res/logo.png')}/>
        </div>
        <ul className="desktop">
          {routes.map((route, index) => (
            <li className={`item ${route.path[this.state.countryCode] === this.state.activeItem ? 'active' : ''}`} key={index} onClick={() => this.onClickLink(route)}>
              {route.title[this.state.countryCode]}
            </li>
          ))}
        </ul>
        <div className="operatorButton">
          <Button
            onClick={() => this.redirectOperatorContainer()}
            title={i18n.translate('NAVIGATION_OPERATOR_BUTTON_TEXT')}
            backgroundColor={colors.primary}/>
        </div>
        <div className="countryContainer">
          <div className="currentCountry" style={{backgroundImage: `url(${this.state.currentCountry.flag})`}} onClick={() => this.togglePossibleCountries()}/>
          <div className="countrySelector" ref={(ref) => this.countrySelector = ref} onClick={() => this.togglePossibleCountries()}/>
          <div className="possibleCountries" ref={(ref) => this.possibleCountries = ref}>
            {this.state.possibleCountries.map((country, index) => (
              <div key={index} className="possibleCountry" onClick={() => this.setCountry(country.countryCode)}>
                <img className="flag" alt="country selector" src={country.flag}/>
                <span>{country.countryCode}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="toggle" onClick={() => this.setState({open: !this.state.open})}>
          <svg
            width="22px"
            height="22px"
            viewBox="0 0 1024 1024">
            <path fill="#fff" d={this.state.open ? closeIcon : toggleIcon}/>
          </svg>
        </div>
      </div>

      <ul className={`mobile ${this.state.open ? 'open' : ''}`}>
        {routes.map((route, index) => (
          <li className={`item ${route.path[this.state.countryCode] === this.state.activeItem ? 'active' : ''}`} key={index} onClick={() => history.push(route.path[this.state.countryCode])}>
            {route.title[this.state.countryCode]}
          </li>
        ))}
      </ul>
    </div>
  );

  redirectOperatorContainer = ({history, client} = this.props) => {
    let route = routerRoutes.find((route) => route.path.EN.split('/').join('') === 'operator');
    history.push(route.path[client.info.location.countryCode]);
  };

  onClickLink = (route) => {
    if (route.scrollTo) {
      scroller.scrollTo(route.scrollTo, {
        duration: 300,
        delay: 50,
        smooth: true,
        offset: 50, // Scrolls to element + 50 pixels down the page
      })
    } else {
      this.props.history.push(route.path[this.state.countryCode]);
    }
  };

  togglePossibleCountries = () => {
    this.countrySelector.classList.toggle('active');
    this.possibleCountries.classList.toggle('visible');
  };

  setCountry = async (countryCode) => {
    let currentRoute = routerRoutes.find((route) => route.path[this.state.countryCode].split('/').join('') === this.state.activeItem.split('/').join(''));
    this.props.history.push(currentRoute.path[countryCode]);

    await this.props.setCountryCode(countryCode);

    this.setState({
      activeItem: currentRoute.path[countryCode],
      currentCountry: countries.find((country) => country.countryCode === countryCode),
      possibleCountries: countries.filter((country) => country.countryCode !== countryCode),
      countryCode: countryCode,
    }, () => {
      this.togglePossibleCountries();
    });
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCountryCode: (countryCode) => dispatch(setCountryCode(countryCode)),
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(Navigation));

export { connectedContainer as Navigation };
