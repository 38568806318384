import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import React from 'react';

// Stylesheets
import colors from '../../colors.scss';
import './toast.scss';

const toasts = [];

export class toast {
  toastMessage = (title, description) => (
    <div className="toastContent">
      <div className="toastMessage">
        <h1 className="toastTitle">{title}</h1>
        <p className="toastDescription">{description}</p>
      </div>
      <div className="close" onClick={() => this.hide()}>
        close
      </div>
    </div>
  );

  generateToastID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });

  show = (title, description, backgroundColor = colors.warning) => {
    let toastID = this.generateToastID();
    toasts.push(toastID);

    // Normally this is root in Reactjs otherwise change the id
    this.rootElement = document.getElementById('root');

    this.toastWrapper = document.createElement('div');
    this.toastWrapper.setAttribute('class', 'toastWrapper');
    this.toastWrapper.setAttribute('id', toastID);

    this.toastContainer = document.createElement('div');
    this.toastContainer.setAttribute("class", "toastContainer");
    this.toastContainer.style.backgroundColor = backgroundColor;

    this.toastWrapper.appendChild(this.toastContainer);
    this.rootElement.firstChild.appendChild(this.toastWrapper);

    // Render the toast
    ReactDOM.render(this.toastMessage(title, description), this.toastContainer);
  };

  hide = () => {
    let toastWrapper = document.getElementById(toasts[toasts.length - 1]);
    let toastIndex = toasts.indexOf(toasts[toasts.length - 1]);
    toastWrapper.classList.add('hide');
    toastWrapper.firstChild.classList.add('hide');

    toasts.splice(toastIndex, 1);

    setTimeout(() => {
      this.rootElement.firstChild.removeChild(toastWrapper);
    }, 200);
  }
}

export const Toast = new toast();
