export const SignupTranslations = {
  SIGNUP_COMPANY_NAME_INPUT: 'Company name',
  SIGNUP_FIRST_NAME_INPUT: 'First name',
  SIGNUP_LAST_NAME_INPUT: 'Last name',
  SIGNUP_EMAIL_INPUT: 'Email address',
  SIGNUP_PASSWORD_INPUT: 'Password',
  SIGNUP_BUTTON_TEXT: 'Signup',

  SIGNUP_NO_SUGARDADDY_ERROR_TITLE: 'Whoops',
  SIGNUP_NO_SUGARDADDY_ERROR_MESSAGE: 'Are you sure you are the companies sugardaddy?',


  SIGNUP_SUCCESS_TITLE: 'Thanks for signing up.',
  SIGNUP_SUCCESS_MESSAGE: 'Please check your email and click "Confirm account" in the message we just sent.',

  SIGNUP_EMAIL_ERROR_TITLE: 'Oops.',
  SIGNUP_EMAIL_ERROR_MESSAGE: 'This email address is already taken.'
};
