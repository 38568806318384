export const clientActionTypes = {
  SET_GDPR_CONSENT: 'SET_GDPR_CONSENT',

  CREATE_DYNAMIC_LINK_PENDING: 'CREATE_DYNAMIC_LINK_PENDING',
  CREATE_DYNAMIC_LINK_SUCCESS: 'CREATE_DYNAMIC_LINK_SUCCESS',
  CREATE_DYNAMIC_LINK_ERROR: 'CREATE_DYNAMIC_LINK_ERROR',

  INIT_PIRATE: 'INIT_PIRATE',

  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',

  GET_LOCATION_INFO_PENDING: 'GET_LOCATION_INFO_PENDING',
  GET_LOCATION_INFO_SUCCESS: 'GET_LOCATION_INFO_SUCCESS',
  GET_LOCATION_INFO_ERROR: 'GET_LOCATION_INFO_ERROR',

  SET_COUNTRY_CODE: 'SET_COUNTRY_CODE',

  GET_PRIVACY_STATEMENT_PENDING: 'GET_PRIVACY_STATEMENT_PENDING',
  GET_PRIVACY_STATEMENT_SUCCESS: 'GET_PRIVACY_STATEMENT_SUCCESS',
  GET_PRIVACY_STATEMENT_ERROR: 'GET_PRIVACY_STATEMENT_ERROR',

  GET_TERMS_AND_CONDITIONS_PENDING: 'GET_TERMS_AND_CONDITIONS_PENDING',
  GET_TERMS_AND_CONDITIONS_SUCCESS: 'GET_TERMS_AND_CONDITIONS_SUCCESS',
  GET_TERMS_AND_CONDITIONS_ERROR: 'GET_TERMS_AND_CONDITIONS_ERROR',

  SET_LOGIN_ORIGIN_PATH: 'SET_LOGIN_ORIGIN_PATH',

  SET_VERSION: 'SET_VERSION',
};
