import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { DownloadComponent } from "../../components/download/download.component";

// Redux
import { setCountryCode } from "../../store/client/client.actions";
import { connect } from 'react-redux';

// Stylesheet
import './verification.container.scss';

class VerificationContainer extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    return {props, state};
  }

  constructor(props) {
    super(props);

    this.state = {
      countryCode: props.match.params.countryCode.toUpperCase(),
      verified: props.match.params.success === 'verified',
    };

    this.props.setCountryCode(this.props.match.params.countryCode.toUpperCase())
      .then(() => {
        i18n.countryCode = this.state.countryCode;
        this.forceUpdate();
      });
  }

  render = ({client, history} = this.props) => (
    <div className="verificationContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
      <div className="container verificationSection">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {this.state.verified && (
              <div>
                <h1 className="title success">{i18n.translate('VERIFICATION_SUCCESS_TITLE')}</h1>
                <h3 className="subTitle">{i18n.translate('VERIFICATION_SUCCESS_SUBTITLE')}</h3>
              </div>
            )}
            {!this.state.verified && (
              <div>
                <h1 className="title error">{i18n.translate('VERIFICATION_ERROR_TITLE')}</h1>
                <h3 className="subTitle">{i18n.translate('VERIFICATION_ERROR_SUBTITLE')}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );

}


const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCountryCode: (countryCode) => dispatch(setCountryCode(countryCode))
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerificationContainer);

export { connectedContainer as VerificationContainer };
