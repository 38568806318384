import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Section, Title } from "../../components/section";

// Redux
import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './business.container.scss';

class BusinessContainer extends React.PureComponent {
  componentDidMount() {
    console.log(this.props.match.params.pirate);
  }

  render = () => (
    <div className="businessContainer">
      <Navigation history={this.props.history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
      <Section name="faq">
        <div className="container questionsContainer">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('BUSINESS_TITLE')}
                     subTitle={i18n.translate('BUSINESS_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
        </div>
      </Section>
      <Footer history={this.props.history}/>
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BusinessContainer);

export { connectedContainer as BusinessContainer };
