import { blogActions } from "./blog.action.types";
import { api } from "../../lib/api";

export const getPosts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: blogActions.GET_POSTS_PENDING,
      });
      let res = await api.getCMSContent('/tables/blog/rows/');
      dispatch({
        type: blogActions.GET_POSTS_SUCCESS,
        posts: res.data
      });
    } catch (error) {
      dispatch({
        type: blogActions.GET_POSTS_ERROR,
        error: error
      });
    }
  }
};
