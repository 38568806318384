import { platform, userAgent } from "../helpers";

let window = {
  dataLayer: [],
};

// sets up datalayer in case of empty array
window.dataLayer = window.dataLayer || [];

export const initTagManager = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    let f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-MHCJ2GS');
};

export const track = (event, kwargs = {}) => {
  // create custom data layer event, you can add additional data to the array
  // https://www.analyticsmania.com/post/google-tag-manager-custom-event-trigger/
  let data = {
    event: event,
    meta: {
      platform: platform(),
      userAgent: userAgent(),
      ...kwargs,
    }
  };


  return window.dataLayer.push(data);
};

export const trackMutiple = (events = []) => {
  events.map((event) => {
    track(event);
  })
};
