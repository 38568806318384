export const contactTranslations = {
  CONTACT_TITLE: 'Contact',
  CONTACT_SUBTITLE: 'Leave feedback or ask any question.',

  CONTACT_FORM_FIRST_NAME_PLACEHOLDER: 'First name',
  CONTACT_FORM_LAST_NAME_PLACEHOLDER: 'Last name',
  CONTACT_FORM_EMAIL_PLACEHOLDER: 'Email address',
  CONTACT_FORM_FEEDBACK_PLACEHOLDER: 'Message',

  CONTACT_FORM_SEND_BUTTON_TEXT: 'Send feedback',

  CONTACT_FORM_SUCCESS_TITLE: 'Feedback received.',
  CONTACT_FORM_SUCCESS_MESSAGE: 'we\'ll get back to you as soon as possible.',

  CONTACT_FORM_ERROR_TITLE: 'Something went wrong.',
  CONTACT_FORM_ERROR_MESSAGE: 'An email addrres and feedback are required.',
};
