import moment from 'moment/min/moment-with-locales';
import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { DownloadComponent } from "../../components/download/download.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Section, Title } from "../../components/section";

// Redux
import { getTermsAndConditions } from "../../store/client/client.actions";
import { connect } from 'react-redux';

// Stylesheet
import colors from '../../colors.scss';
import './terms.container.scss';



class TermsContainer extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    moment.locale(props.client.info.location.countryCode);
    return {props, state};
  }

  constructor(props) {
    super(props);

    this.props.getTermsAndConditions();
    this.state = {};
  }

  render = ({history, client} = this.props) => {
    let translatedTerms = client.termsAndConditions.translations.data.find((terms) => terms.language_code === client.info.location.countryCode);

    return (
      <div className="termsContainer">
        <Navigation history={history}/>
        <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
        <Section name="team" className="no-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <Title
                  className="no-padding-bottom"
                  title={i18n.translate('TERMS_TITLE')}
                  subTitle={`${i18n.translate('TERMS_SUBTITLE')} ${client.termsAndConditions.version} ${moment(client.termsAndConditions.revision_date).format("MMMM YYYY")}`}
                  sectionColor={colors.c1}
                  color={colors.primary}/>
              </div>
            </div>
          </div>
        </Section>
        {translatedTerms && (
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: translatedTerms.terms_and_conditions}}/>
            </div>
          </div>
        )}
        <DownloadComponent history={history}/>
        <Footer history={history}/>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    client: state.client
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTermsAndConditions: () => dispatch(getTermsAndConditions())
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(TermsContainer));

export { connectedContainer as TermsContainer };
