import { testimonialActions } from "./testimonial.action.types";
import { api } from '../../lib/api';

export const getTestimonials = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: testimonialActions.GET_TESTIMONALS_PENDING,
      });

      let res = await api.getCMSContent('/tables/testimonials/rows/');

      dispatch({
        type: testimonialActions.GET_TESTIMONALS_SUCCESS,
        testimonials: res.data
      });

    } catch (error) {
      dispatch({
        type: testimonialActions.GET_TESTIMONALS_ERROR,
        error: error,
      });
    }
  };
};
