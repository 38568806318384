export const homeTranslations = {
    HOME_PARALLAX_TITLE: 'Just take the ride!',
    HOME_PARALLAX_SUBTITLE: 'Zoek, boek en reis met je ticket vanuit de Tranzer app. Met Tranzer koop je in-app tickets voor het openbaar vervoer of bestel je makkelijk een taxi. Tranzer is gratis te downloaden voor iOS en Android.',

    HOME_WHERE_BLOCK_TITLE: 'Waar kan ik Tranzer gebruiken?',
    HOME_WHERE_BLOCK_SUBTITLE: 'Tranzer is te gebruiken in alle treinen, de tram, de bus en de taxi.',
    HOME_WHERE_BLOCK_DESCRIPTION: [
        'Alle nationale treinen in Nederland',
        'Alle internationale treinen in Europa',
        'Alle Connexxion, Breng en Bravo/Hermes bussen',
        'GVB bussen en trams (exclusief nachtbus)',
        'RET bussen en trams (inclusief BOB-bus)',
        'Alle EBS bussen',
        'Diverse taxidiensten'
    ],

    HOME_PAY_BLOCK_TITLE: 'Hoe kan ik betalen bij Tranzer?',
    HOME_PAY_BLOCK_SUBTITLE: 'Alle betalingen geschieden in-app met de door jou gekozen betaalmethode.',
    HOME_PAY_BLOCK_DESCRIPTION: 'Bij het aanmaken van je account kan je een betaalmethode kiezen. Tranzer ondersteunt op dit moment betaling via iDeal of creditcard. Sla je creditcard gegevens op in je account om snel een ticket aan te schaffen.',

    HOME_HOW_BLOCK_TITLE: 'Hoe gebruik ik Tranzer?',
    HOME_HOW_BLOCK_SUBTITLE: 'Nadat je de app hebt gedownload en een account hebt aangemaakt kan jij gaan reizen met Tranzer.',
    HOME_HOW_BLOCK_DESCRIPTION: 'Voer je bestemming in, selecteer één van de reismogelijkheden, koop een ticket en just take the ride!',

    HOME_CORPORATE_TITLE: "Partners",
    HOME_CORPORATE_SUBTITLE: "Een selectie van de organisaties in ons snel groeiende Tranzer netwerk.",

    HOME_MAPS_TITLE: 'Beschikbare stations',
    HOME_MAPS_SUBTITLE: 'Selecteer een land om de beschikbaarheid weer te geven'
};
