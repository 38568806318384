import SwipeableViews from 'react-swipeable-views';
import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Redux
import { getTestimonials } from "../../store/testimonial/testimonial.actions";
import { connect } from 'react-redux';

// Stylesheets
import './testimonial.component.scss';

class Testimonals extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.getTestimonials()
  }

  render = ({testimonial, client} = this.props) => (
    <div className="testimonialComponent">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h1 className="testimonialHeader">
              {i18n.translate('TESTIMONIAL_HEADER')}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <SwipeableViews enableMouseEvents={true}>
              {testimonial.testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial">
                  <h2 className="quote" dangerouslySetInnerHTML={{__html: testimonial.translations.data.find((testimonial) => testimonial.language_code === client.info.location.countryCode).quote}}/>
                  <div className="story" dangerouslySetInnerHTML={{__html: testimonial.translations.data.find((testimonial) => testimonial.language_code === client.info.location.countryCode).story}}/>
                  <p className="author">
                    {testimonial.author}, {testimonial.profession}
                  </p>
                </div>
              ))}
            </SwipeableViews>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    testimonial: state.testimonial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTestimonials: () => dispatch(getTestimonials()),
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(Testimonals));

export { connectedContainer as Testimonals };
