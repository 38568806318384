import {Router as BrowserRouter, Switch, Route} from 'react-router-dom';
import {createBrowserHistory, createHashHistory} from 'history';
import {toJS} from "./lib/helpers";
import React from 'react';

// Routes
import {routes} from "./routes";

// Redux
import {connect} from 'react-redux';

const history = createBrowserHistory();

class ScrollTop extends React.PureComponent {

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    render = ({children} = this.props) => children;
}

class Router extends React.PureComponent {
    static getDerivedStateFromProps(nextProps, prevState) {
        return null;
    }

    constructor(props) {
        super(props);
    }

    redirectPage = () => {
        history.push('');
        return this.renderPage();
    };

    renderPage = ({client} = this.props) => (
        <BrowserRouter history={history}>
            <Switch>
                <ScrollTop>
                    {routes.map((route, index) => (
                        <Route key={index} exact={route.exact} path={route.path[client.info.location.countryCode]}
                               component={route.component}/>
                    ))}
                </ScrollTop>
            </Switch>
        </BrowserRouter>
    );

    render = () => this.matchRoute(history.location.pathname) ? this.renderPage() : this.redirectPage();

    matchRoute = (pathname, {client} = this.props) => {
        let paths = pathname.replace(/^\/|\/$/g, '').split('/');

        let match = false;
        paths.map((path) => {
            if (!match) {
                routes.map((route) => {
                    let routePaths = route.path[client.info.location.countryCode].replace(/^\/|\/$/g, '').split('/');
                    routePaths.map((routePath) => {
                        if (routePath === path) {
                            match = true;
                        }
                    })
                });
            }
        });
        return match;
    }

}

const mapStateToProps = (state) => {
    return {
        client: state.client,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

const connectedContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(toJS(Router));

export {connectedContainer as Router};

