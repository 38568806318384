import { clientActionTypes } from "./client.action.types";
import { i18n } from "../../lib/i18n";
import { api } from "../../lib/api";

export const setGDPRConsent = ({functional, statistics, performance, targeting}) => {
  return async (dispatch) => {
    dispatch({
      type: clientActionTypes.SET_GDPR_CONSENT,
      cookieSettings: {
        functional: true,
        statistics: statistics,
        performance: performance,
        targeting: targeting,
      },
    })
  }
};

export const createDynamicLink = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: clientActionTypes.CREATE_DYNAMIC_LINK_PENDING
      });

      // See firebase docs for more info constructing URL's: https://firebase.google.com/docs/dynamic-links/rest
      let res = await fetch('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCgmb4Gpp36JYlEbz5OtXIDruIpNFJ42XA', {
        method: "POST",
        body: JSON.stringify({
          "longDynamicLink": `https://tranzer.page.link/?link=${api.environment.siteUrl}/home/${data}&st=Just Take The Ride!&sd=Search, book and travel with your ticket from the Tranzer app. With Tranzer you can buy tickets for public transport or book a taxi.&apn=${api.environment.dynamicLink.android.androidPackageName}&afl=https://play.google.com/store/apps/details?id=nl.data_lab.tranzer&ibi=${api.environment.dynamicLink.ios.iosBundleId}&ifl=https://itunes.apple.com/nl/app/tranzer/id1204210982`
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json());

      dispatch({
        type: clientActionTypes.CREATE_DYNAMIC_LINK_SUCCESS,
        link: res
      });

      return res;
    } catch
      (error) {
      dispatch({
        type: clientActionTypes.CREATE_DYNAMIC_LINK_ERROR,
        error: error
      });
    }
  };
};

export const initPirate = (uuid) => {
  return async (dispatch) => {
    dispatch({
      type: clientActionTypes.INIT_PIRATE,
      uuid: uuid,
    });
  };
};


export const getLocationInfo = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: clientActionTypes.GET_LOCATION_INFO_PENDING
      });

      let location = await fetch('http://ip-api.com/json', {
        method: "GET"
      }).then((response) => response.json());

      dispatch({
        type: clientActionTypes.GET_LOCATION_INFO_SUCCESS,
        location: location,
      });
      return location;
    } catch (error) {
      dispatch({
        type: clientActionTypes.GET_LOCATION_INFO_ERROR,
        error: error
      });
    }
  };
};

export const setCountryCode = (countryCode) => async (dispatch) => {
  dispatch({
    type: clientActionTypes.SET_COUNTRY_CODE,
    countryCode: countryCode
  });

  i18n.countryCode = countryCode;
};

export const getPrivacyStatement = () => async (dispatch) => {
  try {
    dispatch({
      type: clientActionTypes.GET_PRIVACY_STATEMENT_PENDING
    });

    let res = await api.getCMSContent('/tables/privacy_statement/rows/');

    dispatch({
      type: clientActionTypes.GET_PRIVACY_STATEMENT_SUCCESS,
      privacyStatement: res.data[0]
    });
  } catch (error) {
    dispatch({
      type: clientActionTypes.GET_PRIVACY_STATEMENT_ERROR,
      error: error
    });
  }
};

export const getTermsAndConditions = () => async (dispatch) => {
  try {
    dispatch({
      type: clientActionTypes.GET_TERMS_AND_CONDITIONS_PENDING
    });

    let res = await api.getCMSContent('/tables/terms_and_conditions/rows/');
    dispatch({
      type: clientActionTypes.GET_TERMS_AND_CONDITIONS_SUCCESS,
      termsAndConditions: res.data[0]
    });
  } catch (error) {
    dispatch({
      type: clientActionTypes.GET_TERMS_AND_CONDITIONS_ERROR,
      error: error
    });
  }
};

export const setLoginOriginPath = (loginOriginPath) => async (dispatch) => {
  dispatch({
    type: clientActionTypes.SET_LOGIN_ORIGIN_PATH,
    loginOriginPath: loginOriginPath
  });
};

export const setVersion = (version) => async (dispatch) => {
  dispatch({
    type: clientActionTypes.SET_VERSION,
    version: version
  });
};
