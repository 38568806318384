import { teamActions } from "./team.action.types";
import { api } from "../../lib/api";


export const getMembers = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: teamActions.GET_MEMBERS_PENDING
      });

      let res = await api.getCMSContent('/tables/members/rows/');
      dispatch({
        type: teamActions.GET_MEMBERS_SUCCESS,
        members: res.data,
      })
    } catch (error) {
      dispatch({
        type: teamActions.GET_MEMBERS_ERROR,
        error: error,
      })
    }
  }
};
