import immutableTransform from 'redux-persist-transform-immutable'
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import browserStorage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import { Map } from 'immutable';
import thunk from 'redux-thunk';

const persistConfig = {
  transforms: [immutableTransform({})],
  key: 'root',
  storage: browserStorage,
  whitelist: ['account', 'blog', 'client', 'contact', 'faq', 'job', 'news', 'team', 'testimonial']
};

export const initStore = (rootReducer) => {
  let middleWare = [
    thunk
  ];

  if (process.env.NODE_ENV === 'development') {
    middleWare.push(createLogger({
      collapsed: true,
      stateTransformer: (state) => Map(state).toJS(),
    }));
  }

  let store = createStore(
    persistReducer(persistConfig, rootReducer),
    undefined,
    compose(
      applyMiddleware(...middleWare)
    )
  );

  let persistor = persistStore(store, {});

  store.flush = async () => {
    await store.dispatch({
      type: 'FLUSH'
    });
  };

  return {store, persistor}
};

