// Containers
import {homeTranslations as homeTranslationsNL} from "./containers/home/home.nl";
import {homeTranslations as homeTranslationsEN} from "./containers/home/home.en";

import {operatorTranslations as operatorTranslationsNL} from "./containers/operator/operator.nl";
import {operatorTranslations as operatorTranslationsEN} from "./containers/operator/operator.en";

import {newsTranslations as newsTranslationsNL} from "./containers/news/news.nl";
import {newsTranslations as newsTranslationsEN} from "./containers/news/news.en";

import {blogTranslations as blogTranslationsNL} from "./containers/blog/blog.nl";
import {blogTranslations as blogTranslationsEN} from "./containers/blog/blog.en";

import {teamTranslations as teamTranslationsNL} from "./containers/team/team.nl";
import {teamTranslations as teamTranslationsEN} from "./containers/team/team.en";

import {jobsTranslations as jobsTranslationsNL} from "./containers/jobs/jobs.nl";
import {jobsTranslations as jobsTranslationsEN} from "./containers/jobs/jobs.en";

import {faqTranslations as faqTranslationsNL} from "./containers/faq/faq.nl";
import {faqTranslations as faqTranslationsEN} from "./containers/faq/faq.en";

import {businessTranslations as businessTranslationsNL} from "./containers/business/business.nl";
import {businessTranslations as businessTranslationsEN} from './containers/business/business.en';

import {contactTranslations as contactTranslationsNL} from "./containers/contact/contact.nl";
import {contactTranslations as contactTranslationsEN} from "./containers/contact/contact.en";

import {cxxJasTranslations as cxxJasTranslationsNL} from "./containers/cxx_jas/cxx_jas.nl";
import {cxxJasTranslations as cxxJasTranslationsEN} from "./containers/cxx_jas/cxx_jas.en";

import {storeTranslations as storeTranslationsNL} from "./containers/store/store.nl";
import {storeTranslations as storeTranslationsEN} from "./containers/store/store.en";

import {termsTranslations as termsTranslationsNL} from "./containers/terms/terms.nl";
import {termsTranslations as termsTranslationsEN} from "./containers/terms/terms.en";

import {privacyTranslations as privacyTranslationsNL} from "./containers/privacy/privacy.nl";
import {privacyTranslations as privacyTranslationsEN} from "./containers/privacy/privacy.en";

import {verificationTranslations as verificationTranslationsNL} from "./containers/verification/verification.nl";
import {verificationTranslations as verificationTranslationsEN} from "./containers/verification/verification.en";

import {LoginTranslations as LoginTranslationsNL} from "./containers/login/login.nl";
import {LoginTranslations as LoginTranslationsEN} from "./containers/login/login.en";

import {SignupTranslations as SignupTranslationsNL} from "./containers/signup/signup.nl";
import {SignupTranslations as SignupTranslationsEN} from "./containers/signup/signup.en";

import {shareTranslations as ShareTranslationsNL} from './containers/share/share.nl';
import {shareTranslations as ShareTranslationsEN} from './containers/share/share.en';

// Components
import {testimonialTranslations as testimonialTranslationsNL} from "./components/testimonial/testimonial.nl";
import {testimonialTranslations as testimonialTranslationsEN} from "./components/testimonial/testimonial.en";

import {downloadTranslations as downloadTranslationsNL} from "./components/download/download.nl";
import {downloadTranslations as downloadTranslationsEN} from "./components/download/download.en";

import {footerTranslations as footerTranslationsNL} from "./components/footer/footer.nl";
import {footerTranslations as footerTranslationsEN} from "./components/footer/footer.en";

import {navigationTranslations as navigationTranslationsNL} from "./components/navigation/navigation.nl";
import {navigationTranslations as navigationTranslationsEN} from "./components/navigation/navigation.en";

const translations = {
    NL: {
        // Containers
        ...homeTranslationsNL,
        ...operatorTranslationsNL,
        ...newsTranslationsNL,
        ...blogTranslationsNL,
        ...teamTranslationsNL,
        ...jobsTranslationsNL,
        ...faqTranslationsNL,
        ...businessTranslationsNL,
        ...contactTranslationsNL,
        ...cxxJasTranslationsNL,
        ...storeTranslationsNL,
        ...termsTranslationsNL,
        ...privacyTranslationsNL,
        ...verificationTranslationsNL,
        ...LoginTranslationsNL,
        ...SignupTranslationsNL,
        ...ShareTranslationsNL,

        // Components
        ...testimonialTranslationsNL,
        ...downloadTranslationsNL,
        ...footerTranslationsNL,
        ...navigationTranslationsNL
    },
    EN: {
        // Containers
        ...homeTranslationsEN,
        ...operatorTranslationsEN,
        ...newsTranslationsEN,
        ...blogTranslationsEN,
        ...teamTranslationsEN,
        ...jobsTranslationsEN,
        ...faqTranslationsEN,
        ...businessTranslationsEN,
        ...contactTranslationsEN,
        ...cxxJasTranslationsEN,
        ...storeTranslationsEN,
        ...termsTranslationsEN,
        ...privacyTranslationsEN,
        ...verificationTranslationsEN,
        ...LoginTranslationsEN,
        ...SignupTranslationsEN,
        ...ShareTranslationsEN,

        // Components
        ...testimonialTranslationsEN,
        ...downloadTranslationsEN,
        ...footerTranslationsEN,
        ...navigationTranslationsEN
    }
};

export class I18N {
    constructor() {
        this.countryCode = 'NL';
    }

    translate = (param) => {
        return translations[this.countryCode][param];
    };

    translateList = (param) => {
        let list = '';
        translations[this.countryCode][param].map((listItem) => {
            return list += `<li>${listItem}</li>`;
        });

        return list;
    };
}

export const i18n = new I18N();
