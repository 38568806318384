import moment from 'moment/min/moment-with-locales';
import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Section, Title } from "../../components/section";
import { DownloadComponent } from "../../components/download/download.component";

// Redux
import { getArticles } from "../../store/news/news.actions";
import { connect } from 'react-redux';

// Stylesheet
import colors from '../../colors.scss';
import './news.container.scss';

class NewsContainer extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    moment.locale(props.client.info.location.countryCode);
    return {props, state};
  }

  constructor(props) {
    super(props);

    this.props.getArticles();

    this.state = {}
  }

  render = ({history, news, client} = this.props) => (
    <div className="newsContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>

      <Section name="news">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('NEWS_TITLE')}
                     subTitle={i18n.translate('NEWS_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
          <div className="row">
            {news.articles.map((article, index) => (
              <div key={index} className="article col-sm-12 col-md-6 col-lg-4">
                {article.image && (
                  <div className="imageContainer" onClick={() => this.readArticle(article.link)}>
                    <div className="image" style={{backgroundImage: `url(${article.image.data.url})`}}/>
                  </div>
                )}
                <h1 className="title">{article.title}</h1>
                <h3 className="timestamp">
                  {moment(article.translations.data.find((article) => article.language_code === client.info.location.countryCode).date_created).format("D MMMM YYYY")}, {article.source}
                </h3>
                <div className="storyContainer" dangerouslySetInnerHTML={{__html: article.translations.data.find((article) => article.language_code === client.info.location.countryCode).story}}/>
                <p className="storyLink">
                  <a href={article.link} target="_blank">
                    {article.translations.data.find((article) => article.language_code === client.info.location.countryCode).readmore_text}
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );

  readArticle = (link) => {
    window.open(link, '_blank');
  }
}


const mapStateToProps = (state) => {
  return {
    client: state.client,
    news: state.news,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticles: () => dispatch(getArticles())
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(NewsContainer));

export { connectedContainer as NewsContainer };
