import {BeatLoader} from 'react-spinners';
import {toJS} from "../../lib/helpers";
import {i18n} from "../../lib/i18n";
import React from 'react';

// Components
import {DownloadComponent} from "../../components/download/download.component";
import {Parallax} from "../../components/parallax/parallax.component";
import {Navigation} from "../../components/navigation/navigation.component";
import {Footer} from "../../components/footer/footer.component";
import {Section, Title} from "../../components/section";

// Redux
import {connect} from 'react-redux';

// Stylesheet
import colors from '../../colors.scss';
import './share.container.scss';


class ShareContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentDidMount() {
        setTimeout(() => {
            window.open(`https://tranzer.page.link/${this.props.match.params.dynamicLink}`, "_self")
        }, 1000)
    }

    render = ({history} = this.props) => (
        <div className="shareContainer">
            <Navigation history={history}/>
            <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>

            <Section name="share">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Title title={i18n.translate('SHARE_TITLE')}
                                   subTitle={i18n.translate('SHARE_SUBTITLE')}
                                   sectionColor={colors.c1}
                                   color={colors.primary}/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 beatLoader">
                            <BeatLoader color={colors.primary} size={10}/>
                        </div>
                    </div>
                </div>
            </Section>
            <DownloadComponent history={history}/>
            <Footer history={history}/>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

const connectedContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(toJS(ShareContainer));

export {connectedContainer as ShareContainer};
