export const operatorTranslations = {
  OPERATOR_PARALLAX_TITLE: 'Just take the ride!',
  OPERATOR_PARALLAX_SUBTITLE: 'Plan, book and travel with your ticket from the Tranzer app. With Tranzer you can buy tickets in-app for public transport or easily book a taxi. Tranzer can be downloaded for free via iOS and Android.Zoek, boek en reis met je ticket vanuit de Tranzer app. Met Tranzer koop je in-app tickets voor het openbaar vervoer of bestel je makkelijk een taxi. Tranzer is gratis te downloaden voor iOS en Android.',

  OPERATOR_FORM_TITLE: 'Contact',
  OPERATOR_FORM_SUBTITLE: 'Laat ons weten hoe we je kunnen helpen.',
  OPERATOR_FORM_FEEDBACK_PLACEHOLDER: 'Jouw bericht',
  OPERATOR_FORM_SEND_BUTTON_TEXT: 'Sluit bij ons aan!',

  OPERATOR_WHERE_BLOCK_TITLE: 'Waar kan ik Tranzer gebruiken?',
  OPERATOR_WHERE_BLOCK_SUBTITLE: 'Tranzer is te gebruiken in alle treinen, de tram, de bus en de taxi.',
  OPERATOR_WHERE_BLOCK_DESCRIPTION: [
    'Alle nationale treinen in Nederland',
    'Alle internationale treinen in Europa',
    'Alle Connexxion, Breng en Bravo/Hermes bussen',
    'GVB bussen en trams (exclusief nachtbus)',
    'RET bussen en trams (inclusief BOB-bus)',
    'Alle EBS bussen',
    'Diverse taxidiensten'
  ],

  OPERATOR_PAY_BLOCK_TITLE: 'Hoe kan ik betalen bij Tranzer?',
  OPERATOR_PAY_BLOCK_SUBTITLE: 'Alle betalingen geschieden in-app met de door jou gekozen betaalmethode.',
  OPERATOR_PAY_BLOCK_DESCRIPTION: 'Bij het aanmaken van je account kan je een betaalmethode kiezen. Tranzer ondersteunt op dit moment betaling via iDeal of creditcard. Sla je creditcard gegevens op in je account om snel een ticket aan te schaffen.',

  OPERATOR_HOW_BLOCK_TITLE: 'Hoe gebruik ik Tranzer?',
  OPERATOR_HOW_BLOCK_SUBTITLE: 'Nadat je de app hebt gedownload en een account hebt aangemaakt kan jij gaan reizen met Tranzer.',
  OPERATOR_HOW_BLOCK_DESCRIPTION: 'Voer je bestemming in, selecteer één van de reismogelijkheden, koop een ticket en just take the ride!',
};
