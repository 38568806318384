import { Terminal } from 'react-feather';
import { toJS } from "../../lib/helpers";
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { DownloadComponent } from "../../components/download/download.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Section, Title } from "../../components/section";
import { Button } from "../../components/button";

// Redux
import { getJobOffers } from "../../store/job/job.actions";
import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './jobs.container.scss';

class JobsContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.getJobOffers();
  }

  render = ({job, client, history, match} = this.props) => (
    <div className="jobsContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
      <Section name="team">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('JOBS_TITLE')}
                     subTitle={i18n.translate('JOBS_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
          <div className="row">
            {job.offers.map((offer, index) => (
              <div key={index} className="col-sm-12 col-md-6 col-lg-6 col-xl-6 jobContainer">
                <Terminal className="jobOfferIcon" color={colors.primary} size={40}/>
                <h2 className="jobOfferHeader">
                  {i18n.translate('JOB_OFFER_HEADER')}
                </h2>
                <h1 className="jobOfferTitle" dangerouslySetInnerHTML={{__html: offer.translations.data.find((offer) => offer.language_code === client.info.location.countryCode).title}}/>
                <div className="jobOfferIntro" dangerouslySetInnerHTML={{__html: offer.translations.data.find((offer) => offer.language_code === client.info.location.countryCode).intro}}/>
                <Button
                  flex={false}
                  backgroundColor={colors.primary}
                  onClick={() => history.push(`${match.path}${offer.slug}`, {offer: offer.translations.data.find((offer) => offer.language_code === client.info.location.countryCode).offer})}
                  title={i18n.translate('JOB_OFFER_READ_MORE')}/>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    job: state.job,
    client: state.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobOffers: () => dispatch(getJobOffers())
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(JobsContainer));

export { connectedContainer as JobsContainer };
