import { track, trackMutiple, initTagManager } from "./lib/tracking";
import ReactDOM from 'react-dom';

import { toJS } from "./lib/helpers";
import { i18n } from "./lib/i18n";
import { Router } from "./router";
import { api } from "./lib/api";
import React from 'react';

// Components
import { CookieConsent } from "./components/gdpr/cookie.consent.component";

// Redux
import { initPirate, setGDPRConsent } from "./store/client/client.actions";
import { initStore, initReducer } from "./store";
import { connect, Provider } from 'react-redux';

// Stylesheets
import './app.scss';

export const {store, persistor} = initStore(initReducer());

export class Root extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      storeLoading: true,
      pirateUUID: null,
    };
  }

  componentDidMount() {
    persistor.subscribe(() => this.restoreStorage());
  }

  restoreStorage = async ({client, account} = this.props) => {
    api.tokens = account.tokens;
    i18n.countryCode = client.info.location.countryCode;

    if (client.GDPR.consent === false) {
      await this.saveCookieConsent(client.GDPR.cookies);
    }

    this.setState({
      storeLoading: false,
      // Restore possible pirateUUID from persistent storage
      pirateUUID: this.props.client.pirate.uuid || this.pirateUUID(),
    }, async () => {
      await this.props.initPirate(this.state.pirateUUID);
      // Dispatch either the existing pirateUUID or the newly created one to GA and GTM
      track('initPirate', this.state.pirateUUID);
    });
  };

  renderSplash = () => null;

  renderApp = ({client} = this.props) => (
    <div className="appWrapper">
      {!client.GDPR.consent === true && (
        <CookieConsent
          onPressAllow={async (cookieSettings) => await this.saveCookieConsent(cookieSettings)}/>
      )}
      <div id="app">
        <Router/>
      </div>
    </div>
  );

  render = () => {
    return this.state.storeLoading ? this.renderSplash() : this.renderApp();
  };

  pirateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  saveCookieConsent = async (cookieSettings) => {
    await this.props.setGDPRConsent(cookieSettings);
    if (cookieSettings.statistics) {
      // GTM
      let gtmScript = document.createElement("script");
      gtmScript.innerHTML = initTagManager();
      document.head.appendChild(gtmScript);

      let gtmContainer = document.getElementById("gtm");
      gtmContainer.innerHTML = "<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MHCJ2GS\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"/>"
    }
    if (cookieSettings.performance) {
      track('hotjarPixel');
    }
    if (cookieSettings.targeting) {
      trackMutiple(['adRollPixel', 'facebookPixel', 'linkedInPixel', 'twitterPixel']);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initPirate: (uuid) => dispatch(initPirate(uuid)),
    setGDPRConsent: (cookieSettings) => dispatch(setGDPRConsent(cookieSettings)),
  }
};

const AppWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(Root));

class App extends React.PureComponent {
  render = () => (
    <Provider store={store}>
      <AppWithState/>
    </Provider>
  )
}


ReactDOM.render(<App/>, document.getElementById('root'));
