import { toJS } from "../../lib/helpers";
import React from 'react';

// Components
import { DownloadComponent } from "../../components/download/download.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";

// Redux
import { getPosts } from "../../store/blog/blog.actions";
import { connect } from 'react-redux';

// Stylesheets
import './post.container.scss';


class PostContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.getPosts();

    this.state = {
      post: (props.location.state && props.location.state.post) || null,
    };

    if (!this.state.post) {
      props.history.push(props.match.url.replace(props.match.params.post, ''));
    }
  }

  render = ({blog, client, history} = this.props) => (
    <div className="blogContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>

      {this.state.post && (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: this.state.post.blog}}/>
          </div>
        </div>
      )}

      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    blog: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPosts: () => dispatch(getPosts())
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(PostContainer));

export { connectedContainer as PostContainer };
