import { jobActions } from "./job.action.types";
import { Map, List } from 'immutable';
import { accountState } from "../account/account.reducer";

export const jobState = new Map({
  offersPending: false,
  offersError: null,
  offers: new List([])
});

export const jobReducer = (state = jobState, action) => {
  switch (action.type) {
    case jobActions.GET_JOB_OFFERS_PENDING:
      return state
        .set('offersPending', true);
    case jobActions.GET_JOB_OFFERS_SUCCESS:
      return state
        .set('offersPending', false)
        .set('offers', new List(action.offers));
    case jobActions.GET_JOB_OFFERS_ERROR:
      return state
        .set('offersPending', false)
        .set('offersError', action.error);
    case "FLUSH":
      return jobState;
    default:
      return state;
  }
};
