import { newsActions } from "./news.action.types";
import { Map, List } from 'immutable';

export const newsState = new Map({
  articlesPending: false,
  articlesError: null,
  articles: new List([])
});

export const newsReducer = (state = newsState, action) => {
  switch (action.type) {
    case newsActions.GET_ARTICLES_PENDING:
      return state.set('articlesPending', true);
    case newsActions.GET_ARTICLES_SUCCESS:
      return state
        .set('articlesPending', false)
        .set('articles', action.articles);
    case newsActions.GET_ARTICLES_ERROR:
      return state
        .set('articlesPending', false)
        .set('articlesError', action.error);
    case "FLUSH":
      return newsState;
    default:
      return state;
  }
};
