import {Globe, Settings, CreditCard} from 'react-feather';
import {environment} from "../../lib/enviroment";
import {toJS} from "../../lib/helpers";
import {i18n} from "../../lib/i18n";
import LazyLoad from 'react-lazyload';
import React from 'react';

// Components
import {Parallax} from "../../components/parallax/parallax.component";
import {Navigation} from "../../components/navigation/navigation.component";
import {Footer} from "../../components/footer/footer.component";
import {DownloadComponent} from "../../components/download/download.component";
import {Section, Title} from "../../components/section";
import {Testimonals} from "../../components/testimonial/testimonial.component";
import {Map} from "../../components/map/map.component";

// Redux
import {connect} from 'react-redux';

// Statics
import mapStyles from "./res/map.styles";

// Stylesheet
import colors from '../../colors.scss';
import './home.container.scss';


class HomeContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            geoJsonMarkers: null,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.payOffContainer.classList.add('visible');
        }, 300);

        setTimeout(() => {
            this.appPreviewContainer.classList.add('visible');
        }, 500);
    }

    render = ({reseller, history} = this.props) => (
        <div className="homeContainer">
            <Navigation history={history}/>
            <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="90vh" shader={true}>
                <div className="container parallaxContent">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 payOffContainer" ref={(ref) => this.payOffContainer = ref}>
                            <h1 className="title">{i18n.translate('HOME_PARALLAX_TITLE')}</h1>
                            <p className="text">{i18n.translate('HOME_PARALLAX_SUBTITLE')}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 appPreviewContainer" ref={(ref) => this.appPreviewContainer = ref}>
                            <img alt="App Preview" ref={(ref) => this.appPreviewHome = ref} className="appPreview" src={require('./res/tranzer_home.png')}/>
                            <img alt="App Preview" ref={(ref) => this.appPreviewPlan = ref} className="appPreview" src={require('./res/tranzer_plan.png')}/>
                        </div>
                    </div>
                </div>
            </Parallax>

            <LazyLoad once={true}>
                <DownloadComponent history={history}/>
            </LazyLoad>

            <LazyLoad once={true}>
                <Section name="about">
                    <div className="container aboutContainer">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 sectionCard">
                                <Globe className="sectionIcon" color={colors.primary} size={40}/>
                                <h3 className="sectionHeader">{i18n.translate('HOME_WHERE_BLOCK_TITLE')}</h3>
                                <p className="sectionMainText">
                                    {i18n.translate('HOME_WHERE_BLOCK_SUBTITLE')}
                                </p>
                                <ul className="sectionList" dangerouslySetInnerHTML={{__html: i18n.translateList('HOME_WHERE_BLOCK_DESCRIPTION')}}/>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 sectionCard">
                                <CreditCard className="sectionIcon" color={colors.primary} size={40}/>
                                <h3 className="sectionHeader">{i18n.translate('HOME_PAY_BLOCK_TITLE')}</h3>
                                <p className="sectionMainText">
                                    {i18n.translate('HOME_PAY_BLOCK_SUBTITLE')}
                                </p>
                                <p className="sectionSubText">
                                    {i18n.translate('HOME_PAY_BLOCK_DESCRIPTION')}
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 sectionCard">
                                <Settings className="sectionIcon" color={colors.primary} size={40}/>
                                <h3 className="sectionHeader">{i18n.translate('HOME_HOW_BLOCK_TITLE')}</h3>
                                <p className="sectionMainText">
                                    {i18n.translate('HOME_HOW_BLOCK_SUBTITLE')}
                                </p>
                                <p className="sectionSubText">
                                    {i18n.translate('HOME_HOW_BLOCK_DESCRIPTION')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Section>
            </LazyLoad>

            <LazyLoad>
                <Section name="partners" backgroundColor={colors.c1}>
                    <div className="container partnerContainer">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <Title title={i18n.translate('HOME_CORPORATE_TITLE')}
                                       subTitle={i18n.translate('HOME_CORPORATE_SUBTITLE')}
                                       sectionColor={colors.c1}
                                       color={colors.primary}/>
                            </div>
                        </div>
                        <div className="row partners">
                            <div className="partnerTile col-sm-6 col-md-4 col-lg-2 col-xl-1" style={{backgroundColor: '#00a1e4'}} onClick={() => window.open('https://youtu.be/s-xmznpOBdY', '_blank')}>
                                <div className="partnerLogo" style={{backgroundImage: `url(${require('./res/partners/klm.png')})`}}/>
                            </div>

                            <div className="partnerTile col-sm-6 col-md-4 col-lg-2 col-xl-1" style={{backgroundColor: '#95C11F'}} onClick={() => window.open('https://youtu.be/zkRkCPq2sTg ', '_blank')}>
                                <div className="partnerLogo" style={{backgroundImage: `url(${require('./res/partners/travelcard.svg')})`}}/>
                            </div>

                            <div className="partnerTile col-sm-6 col-md-4 col-lg-2 col-xl-1" style={{backgroundColor: '#FF5F00'}} onClick={() => window.open('https://www.startpath.com/startups/', '_blank')}>
                                <div className="partnerLogo" style={{backgroundImage: `url(${require('./res/partners/mastercard_startpath.png')})`}}/>
                            </div>
                        </div>
                    </div>
                </Section>
            </LazyLoad>

            <LazyLoad>
                <Section name="video">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <Title title="Video"
                                       subTitle="Tranzer! Just take the ride!"
                                       sectionColor={colors.c1}
                                       color={colors.primary}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="videoContainer">
                                    <iframe title="Video" className="video" width="560" height="315" src="https://www.youtube.com/embed/FqhjuWeto-w" frameBorder="0" allow="autoplay; encrypted-media"
                                            allowFullScreen/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
            </LazyLoad>

            <Testimonals/>

            <Section name="video" className="no-padding-bottom" backgroundColor={colors.c1}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Title title={i18n.translate('HOME_MAPS_TITLE')}
                                   subTitle={i18n.translate('HOME_MAPS_SUBTITLE')}
                                   sectionColor={colors.c1}
                                   color={colors.primary}/>
                        </div>
                    </div>
                </div>
            </Section>
            <LazyLoad>
                <Map
                    ref={(ref) => this.map = ref}
                    height={600}
                    geoJsonURL={`${environment[process.env.NODE_ENV].siteUrl}/static/geojson/countries.json`}
                    geoJsonMarkersURL={this.state.geoJsonMarkers}

                    onClickFeature={(feature) => this.getGeoJsonMarkersURL(feature.getProperty('CNTRY_NAME'))}
                    options={{
                        styles: mapStyles,
                        disableDefaultUI: true,
                        zoomControl: true,
                        center: {lat: 48.301010, lng: 13.401044},
                        zoom: 4,
                    }}/>
            </LazyLoad>

            <DownloadComponent history={history}/>
            <Footer history={this.props.history}/>
        </div>
    );

    getGeoJsonMarkersURL = async (countryName) => {
        let countryCodes = {
            "POLAND": "PL",
            "CZECH REPUBLIC": "CZ",
            "ITALY": "IT",
            "GERMANY": "DE",
            "SPAIN": "ES",
            "PORTUGAL": "PT",
            "NETHERLANDS": "NL",
            "AUSTRIA": "AT",
            "BELGIUM": "BE",
            "FRANCE": "FR",
            "SWITZERLAND": "CH",
            "DENMARK": "DK"
        };

        this.setState({geoJsonMarkers: `${environment[process.env.NODE_ENV].siteUrl}/static/geojson/${countryCodes[countryName.toUpperCase()]}.json`});
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

const connectedContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(toJS(HomeContainer));

export {connectedContainer as HomeContainer};
