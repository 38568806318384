export const operatorTranslations = {
  OPERATOR_PARALLAX_TITLE: 'Just take the ride!',
  OPERATOR_PARALLAX_SUBTITLE: 'Plan, book and travel with your ticket from the Tranzer app. With Tranzer you can buy tickets in-app for public transport or easily book a taxi. Tranzer can be downloaded for free via iOS and Android.Zoek, boek en reis met je ticket vanuit de Tranzer app. Met Tranzer koop je in-app tickets voor het openbaar vervoer of bestel je makkelijk een taxi. Tranzer is gratis te downloaden voor iOS en Android.',

  OPERATOR_FORM_TITLE: 'Contact',
  OPERATOR_FORM_SUBTITLE: 'Let us know what we can do for you.',
  OPERATOR_FORM_FEEDBACK_PLACEHOLDER: 'Message',
  OPERATOR_FORM_SEND_BUTTON_TEXT: 'Join us today!',

  OPERATOR_WHERE_BLOCK_TITLE: 'Where can I use Tranzer?',
  OPERATOR_WHERE_BLOCK_SUBTITLE: 'Tranzer is currently available on train, taxi, tram and bus services.',
  OPERATOR_WHERE_BLOCK_DESCRIPTION: [
    'All national trains in the Netherlands',
    'International trains in Europe',
    'All Connexxion, Breng and Bravo/Hermes busses',
    'GVB busses and trams (excluding night bus)',
    'RET busses and trams (including BOB-bus)',
    'All EBS busses',
    'Several taxi services'
  ],

  OPERATOR_PAY_BLOCK_TITLE: 'How can I pay with Tranzer?',
  OPERATOR_PAY_BLOCK_SUBTITLE: 'All payments are managed via the app, using your preferred method of payment.',
  OPERATOR_PAY_BLOCK_DESCRIPTION: 'When setting-up your account, you can select the payment method you prefer. Tranzer currently supports payment using iDeal or credit card. Save your credit card details in your account to quickly purchase a ticket.',

  OPERATOR_HOW_BLOCK_TITLE: 'How do I use Tranzer?',
  OPERATOR_HOW_BLOCK_SUBTITLE: 'To use Tranzer all you need to do is download the app and set-up an account.',
  OPERATOR_HOW_BLOCK_DESCRIPTION: 'You can enter your destination, select from the public transport options identified by Tranzer that best suits your needs and just take the ride!',
};
