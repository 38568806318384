import { Globe, Settings, CreditCard, User, Mail } from 'react-feather';
import { emailIsValid, toJS } from "../../lib/helpers";
import { Toast } from "../../lib/toast/toast";
import { scroller } from 'react-scroll';
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { DownloadComponent } from "../../components/download/download.component";
import { Testimonals } from "../../components/testimonial/testimonial.component";
import { Section, Title } from "../../components/section";
import { Button } from "../../components/button";

// Redux
import { sendContactForm } from "../../store/contact/contact.actions";
import { connect } from 'react-redux';

// Stylesheet
import colors from '../../colors.scss';
import './operator.container.scss';

class OperatorContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      feedback: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.operatorPayOffContainer.classList.add('visible');
    }, 300);

    setTimeout(() => {
      this.operatorAppPreviewContainer.classList.add('visible');
    }, 500);
  }

  render = ({contact, history} = this.props) => (
    <div className="operatorContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="90vh" shader={true}>
        <div className="container parallaxContent">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 payOffContainer" ref={(ref) => this.operatorPayOffContainer = ref}>
              <h1 className="title">{i18n.translate('OPERATOR_PARALLAX_TITLE')}</h1>
              <p className="text">{i18n.translate('OPERATOR_PARALLAX_SUBTITLE')}</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 appPreviewContainer" ref={(ref) => this.operatorAppPreviewContainer = ref}>
              <img ref={(ref) => this.appPreviewHome = ref} className="appPreview" alt="app preview" src={require('./res/tranzer_home.png')}/>
              <img ref={(ref) => this.appPreviewPlan = ref} className="appPreview" alt="app preview" src={require('./res/tranzer_plan.png')}/>
            </div>
          </div>
        </div>
      </Parallax>

      <DownloadComponent history={history}/>

      <Section name="about">
        <div className="container aboutContainer">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 sectionCard">
              <Globe className="sectionIcon" color={colors.primary} size={40}/>
              <h3 className="sectionHeader">{i18n.translate('OPERATOR_WHERE_BLOCK_TITLE')}</h3>
              <p className="sectionMainText">
                {i18n.translate('OPERATOR_WHERE_BLOCK_SUBTITLE')}
              </p>
              <ul className="sectionList" dangerouslySetInnerHTML={{__html: i18n.translateList('OPERATOR_WHERE_BLOCK_DESCRIPTION')}}/>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 sectionCard">
              <CreditCard className="sectionIcon" color={colors.primary} size={40}/>
              <h3 className="sectionHeader">{i18n.translate('OPERATOR_PAY_BLOCK_TITLE')}</h3>
              <p className="sectionMainText">
                {i18n.translate('OPERATOR_PAY_BLOCK_SUBTITLE')}
              </p>
              <p className="sectionSubText">
                {i18n.translate('OPERATOR_PAY_BLOCK_DESCRIPTION')}
              </p>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 sectionCard">
              <Settings className="sectionIcon" color={colors.primary} size={40}/>
              <h3 className="sectionHeader">{i18n.translate('OPERATOR_HOW_BLOCK_TITLE')}</h3>
              <p className="sectionMainText">
                {i18n.translate('OPERATOR_HOW_BLOCK_SUBTITLE')}
              </p>
              <p className="sectionSubText">
                {i18n.translate('OPERATOR_HOW_BLOCK_DESCRIPTION')}
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="60vh" shader={true}>
        <div className="container parallaxContent">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 payOffContainer visible">
              <h1 className="title">Join us as an operator!</h1>
              <p className="text">We’re looking for operators in airport areas all over the world.
                Just share your pricing table with us and your transport services can also be offered in Tranzer!</p>
              <p className="text">We offer:</p>
              <ul className="parallaxList">
                <li>No investment on equipment</li>
                <li>Start immediately</li>
                <li>International travellers</li>
              </ul>
              <Button
                onClick={() => this.scrollToContact()}
                title="Join us!"
                backgroundColor={colors.primary}/>
            </div>
          </div>
        </div>
      </Parallax>

      <Section name="contact">
        <div className="container questionsContainer">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('OPERATOR_FORM_TITLE')}
                     subTitle={i18n.translate('OPERATOR_FORM_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2"/>
            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <div className="formGroup flex-row">
                <div className="inputField">
                  <input
                    onChange={(event) => this.setState({firstName: event.target.value})}
                    onKeyDown={(event) => this.focusNext({event: event, ref: this.lastNameInput})}
                    value={this.state.firstName}
                    placeholder={i18n.translate('CONTACT_FORM_FIRST_NAME_PLACEHOLDER')}
                    type="text"
                    name="firstName"/>
                  <User size={20} color={colors.darkgray}/>
                </div>
                <div className="inputField">
                  <input
                    onChange={(event) => this.setState({lastName: event.target.value})}
                    onKeyDown={(event) => this.focusNext({event: event, ref: this.emailInput})}
                    ref={(ref) => this.lastNameInput = ref}
                    value={this.state.lastName}
                    placeholder={i18n.translate('CONTACT_FORM_LAST_NAME_PLACEHOLDER')}
                    type="text"
                    name="lastName"/>
                  <User size={20} color={colors.darkgray}/>
                </div>
              </div>

              <div className="inputField" ref={(ref) => this.emailField = ref}>
                <input
                  onChange={(event) => this.setState({email: event.target.value})}
                  onKeyDown={(event) => this.focusNext({event: event, ref: this.feedbackInput, focusField: this.emailField, validated: emailIsValid(this.state.email)})}
                  ref={(ref) => this.emailInput = ref}
                  value={this.state.email}
                  placeholder={i18n.translate('CONTACT_FORM_EMAIL_PLACEHOLDER')}
                  type="text"
                  name="last_name"/>
                <Mail size={20} color={colors.darkgray}/>
              </div>

              <div className="inputField" ref={(ref) => this.feedbackField = ref}>
                <textarea
                  onChange={(event) => this.setState({feedback: event.target.value})}
                  ref={(ref) => this.feedbackInput = ref}
                  value={this.state.feedback}
                  rows={5}
                  placeholder={i18n.translate('OPERATOR_FORM_FEEDBACK_PLACEHOLDER')}
                  name="last_name"/>
              </div>

              <Button
                backgroundColor={colors.primary}
                isLoading={contact.contactFormPending}
                onClick={() => this.sendContactForm()}
                title={i18n.translate('OPERATOR_FORM_SEND_BUTTON_TEXT')}/>
            </div>
          </div>
        </div>
      </Section>

      <Testimonals/>
      <DownloadComponent history={history}/>
      <Footer history={this.props.history}/>
    </div>
  );

  sendContactForm = () => {
    if (this.state.email && this.state.feedback) {
      this.props.sendContactForm(`${this.state.firstName} ${this.state.firstName}`, this.state.email, this.state.feedback, 'web')
        .then(() => this.setState({firstName: '', lastName: '', email: '', feedback: ''}, () => Toast.show(
          i18n.translate('CONTACT_FORM_SUCCESS_TITLE'),
          i18n.translate('CONTACT_FORM_SUCCESS_MESSAGE'),
          colors.primary
        )))
    } else {
      Toast.show(
        i18n.translate('CONTACT_FORM_ERROR_TITLE'),
        i18n.translate('CONTACT_FORM_ERROR_MESSAGE'),
      )
    }
  };

  focusNext = ({event, ref, focusField, validated}) => {
    if (event.key === 'Enter') {
      if (validated === false) {
        if (focusField) {
          focusField.classList.add('error');
        }
      } else {
        if (focusField) {
          focusField.classList.remove('error');
        }
        ref.focus();
      }
    }
  };

  scrollToContact = () => {
    scroller.scrollTo('contact', {
      duration: 300,
      delay: 50,
      smooth: true,
      offset: -50,
    })
  }
}


const mapStateToProps = (state) => {
  return {
    client: state.client,
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendContactForm: (name, email, feedback, platform) => dispatch(sendContactForm(name, email, feedback, platform))
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(OperatorContainer));

export { connectedContainer as OperatorContainer };
