import { emailIsValid, toJS } from "../../lib/helpers";
import { Toast } from "../../lib/toast/toast";
import { Mail, User } from 'react-feather';
import { i18n } from "../../lib/i18n";
import React from 'react';

// Components
import { DownloadComponent } from "../../components/download/download.component";
import { Parallax } from "../../components/parallax/parallax.component";
import { Navigation } from "../../components/navigation/navigation.component";
import { Footer } from "../../components/footer/footer.component";
import { Section, Title } from "../../components/section";
import { Button } from "../../components/button";

// Redux
import { sendContactForm } from "../../store/contact/contact.actions";
import { connect } from 'react-redux';

// Stylesheets
import colors from '../../colors.scss';
import './contact.container.scss';


class ContactContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      feedback: '',
    };
  }

  render = ({history, contact} = this.props) => (
    <div className="contactContainer">
      <Navigation history={history}/>
      <Parallax backgroundImage={require('../../assets/images/background.jpg')} fixedHeight="20vh"/>
      <Section name="contact">
        <div className="container questionsContainer">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Title title={i18n.translate('CONTACT_TITLE')}
                     subTitle={i18n.translate('CONTACT_SUBTITLE')}
                     sectionColor={colors.c1}
                     color={colors.primary}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2"/>
            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <div className="formGroup flex-row">
                <div className="inputField">
                  <input
                    onChange={(event) => this.setState({firstName: event.target.value})}
                    onKeyDown={(event) => this.focusNext({event: event, ref: this.lastNameInput})}
                    value={this.state.firstName}
                    placeholder={i18n.translate('CONTACT_FORM_FIRST_NAME_PLACEHOLDER')}
                    type="text"
                    name="firstName"/>
                  <User size={20} color={colors.darkgray}/>
                </div>
                <div className="inputField">
                  <input
                    onChange={(event) => this.setState({lastName: event.target.value})}
                    onKeyDown={(event) => this.focusNext({event: event, ref: this.emailInput})}
                    ref={(ref) => this.lastNameInput = ref}
                    value={this.state.lastName}
                    placeholder={i18n.translate('CONTACT_FORM_LAST_NAME_PLACEHOLDER')}
                    type="text"
                    name="lastName"/>
                  <User size={20} color={colors.darkgray}/>
                </div>
              </div>

              <div className="inputField" ref={(ref) => this.emailField = ref}>
                <input
                  onChange={(event) => this.setState({email: event.target.value})}
                  onKeyDown={(event) => this.focusNext({event: event, ref: this.feedbackInput, focusField: this.emailField, validated: emailIsValid(this.state.email)})}
                  ref={(ref) => this.emailInput = ref}
                  value={this.state.email}
                  placeholder={i18n.translate('CONTACT_FORM_EMAIL_PLACEHOLDER')}
                  type="text"
                  name="last_name"/>
                <Mail size={20} color={colors.darkgray}/>
              </div>

              <div className="inputField" ref={(ref) => this.feedbackField = ref}>
                <textarea
                  onChange={(event) => this.setState({feedback: event.target.value})}
                  ref={(ref) => this.feedbackInput = ref}
                  value={this.state.feedback}
                  rows={5}
                  placeholder={i18n.translate('CONTACT_FORM_FEEDBACK_PLACEHOLDER')}
                  name="last_name"/>
              </div>

              <Button
                backgroundColor={colors.primary}
                isLoading={contact.contactFormPending}
                onClick={() => this.sendContactForm()}
                title={i18n.translate('CONTACT_FORM_SEND_BUTTON_TEXT')}/>
            </div>
          </div>
        </div>
      </Section>
      <DownloadComponent history={history}/>
      <Footer history={history}/>
    </div>
  );

  sendContactForm = () => {
    if (this.state.email && this.state.feedback) {
      this.props.sendContactForm(`${this.state.firstName} ${this.state.firstName}`, this.state.email, this.state.feedback, 'operator')
        .then(() => this.setState({firstName: '', lastName: '', email: '', feedback: ''}, () => Toast.show(
          i18n.translate('CONTACT_FORM_SUCCESS_TITLE'),
          i18n.translate('CONTACT_FORM_SUCCESS_MESSAGE'),
          colors.primary
        )))
    } else {
      Toast.show(
        i18n.translate('CONTACT_FORM_ERROR_TITLE'),
        i18n.translate('CONTACT_FORM_ERROR_MESSAGE'),
      )
    }
  };

  focusNext = ({event, ref, focusField, validated}) => {
    if (event.key === 'Enter') {
      if (validated === false) {
        if (focusField) {
          focusField.classList.add('error');
        }
      } else {
        if (focusField) {
          focusField.classList.remove('error');
        }
        ref.focus();
      }
    }
  }

}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendContactForm: (name, email, feedback, platform) => dispatch(sendContactForm(name, email, feedback, platform))
  }
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJS(ContactContainer));

export { connectedContainer as ContactContainer };
